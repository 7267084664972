import React from "react";
import { FormHelperText } from "@material-ui/core";

const TextareaHelperText = ({
  error,
  helperText,
  text,
  limit,
}: {
  error: boolean;
  helperText?: string;
  text?: string;
  limit: number;
}) => {
  const exceeded = (text?.length ?? 0) > limit;
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <FormHelperText error={error} style={{ margin: 0 }}>
        {helperText}
      </FormHelperText>
      <FormHelperText error={error} style={{ margin: 0 }}>
        <span
          style={{
            ...(exceeded ? { fontSize: "0.90rem", fontWeight: 700 } : {}),
          }}
        >
          {text?.length ?? 0}
        </span>
        <span>/</span>
        <span>{limit}</span>
      </FormHelperText>
    </div>
  );
};

export default TextareaHelperText;
