import * as React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: `calc(100vh - (( ${theme.spacing(10)}px * 2 ) + ( ${theme.spacing(
        3
      )}px * 2 )) - 53px)`,
    },
  })
);

export type LoadinContainerProps = {
  loading: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const LoadinContainer = (props: LoadinContainerProps) => {
  const { loading, children, style } = props;
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <div style={style} className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadinContainer;
