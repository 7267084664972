import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { AppointmentType } from "@udok/lib/api/models";
import {
  createAppointmentType,
  fetchAppointmentType,
  fetchAppointmentTypes,
  updateAppointmentType,
  deleteAppointmentType,
} from "@udok/lib/api/appointmentType";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  appoTypeByID: { [aptyID: string]: AppointmentType };
  doctorAppointmentTypes: string[];
};

// Reducers
const initialState: InitialState = {
  appoTypeByID: {},
  doctorAppointmentTypes: [],
};

class AppointmentTypeSlice extends Hen<InitialState> {
  appoTypeLoaded(appoType: AppointmentType) {
    this.state.appoTypeByID[appoType.aptyID] = appoType;
  }
  removeAppoType(appoType: AppointmentType) {
    delete this.state.appoTypeByID[appoType.aptyID];
  }
  appoTypesLoaded(appoType: AppointmentType[]) {
    let docList = this.state.doctorAppointmentTypes;
    appoType.forEach((appt) => {
      if (docList.indexOf(appt.aptyID) === -1) {
        docList = [...docList, appt.aptyID];
      }
      this.state.appoTypeByID[appt.aptyID] = appt;
    });
    this.state.doctorAppointmentTypes = docList;
  }
}

export const [Reducer, actions] = hen(new AppointmentTypeSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.appointmentType;

export const getListAppointmentType = createSelector(mainSelector, (state) => {
  return {
    list: state.doctorAppointmentTypes
      .map((aptyID) => state.appoTypeByID[aptyID])
      .filter((apt) => !!apt && !apt?.deletedAt)
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
  };
});

export const getListAllAppointmentType = createSelector(
  mainSelector,
  (state) => {
    return {
      list: Object.keys(state.appoTypeByID)
        .map((aptyID) => state.appoTypeByID[aptyID])
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);

export const getOneAppointmentType = (props: { aptyID?: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      appoType: props?.aptyID ? state.appoTypeByID[props.aptyID] : undefined,
    };
  });

export const getAppointmentTypes = (
  state: RootState,
  props: { Idlist: string[] }
) =>
  createSelector(mainSelector, (state) => {
    return {
      appoTypes: props.Idlist.map((id) => state.appoTypeByID[id] ?? {}),
    };
  });

// Actions
export function createOneAppointmentType(
  appoType: AppointmentType
): AppThunk<Promise<AppointmentType>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createAppointmentType(apiToken, appoType)
      .then((r) => {
        dispatch(actions.appoTypeLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Tipo de atendimento criado com sucesso",
          }) as any
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentTypes(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentTypes(apiToken)
      .then((r) => {
        dispatch(actions.appoTypesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneAppointmentType(
  aptyID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentType(apiToken, aptyID)
      .then((r) => {
        dispatch(actions.appoTypeLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeAppointmentType(aptyID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteAppointmentType(apiToken, aptyID)
      .then((r) => {
        dispatch(actions.removeAppoType(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeAppointmentType(
  appoType: AppointmentType
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateAppointmentType(apiToken, appoType)
      .then((r) => {
        dispatch(actions.appoTypeLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedAppointmentType(
  aptyID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const typeExist = Boolean(state.appointmentType.appoTypeByID[aptyID]);
    if (typeExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneAppointmentType(aptyID));
  };
}
