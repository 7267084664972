import * as React from "react";
import { useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import Form, { InitialValues } from "containers/Schedule/Form";
import { TypeHour } from "containers/Schedule/FormUtil";
import {
  Schedule,
  ScheduleType,
  Preferences,
  PlanFeaturesPermissions,
} from "@udok/lib/api/models";
import {
  formatMoneyInFloat,
  format,
  formatOffset,
} from "@udok/lib/internal/util";
import { DefaultCancelAfterMinutes } from "@udok/lib/internal/constants";
import { AppDispatch } from "ducks/state";
import { createOneSchedule } from "ducks/schedule";
import { useGetFeatureAccessPermission } from "hooks/user";
import { useGetOneDoctorPreference } from "hooks/doctorPreferences";
import { createModal } from "components/Dialog/PromiseDialog";
import DialogSchedulePlans from "containers/SchedulePlans";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type ScheduleCreateProps = {
  onSubmit?: () => void;
  initialValues?: Partial<InitialValues>;
};

const [rendererDialog, promiseDialog] = createModal(DialogSchedulePlans);
const FeatureSchedule = PlanFeaturesPermissions.UnlimitedSchedule;
const FeatureBank = PlanFeaturesPermissions.BankAccounts;
const ScheduleCreate = (props: ScheduleCreateProps) => {
  const { onSubmit, initialValues } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [permissions] = useGetFeatureAccessPermission([
    FeatureSchedule,
    FeatureBank,
  ]);
  const [load, pref] = useGetOneDoctorPreference(
    Preferences.appointmentDefaultAppointmentType
  );
  const allowedSchedule = permissions[FeatureSchedule].allowed;
  const allowedBank = permissions[FeatureBank].allowed;

  const openDialog = React.useCallback(
    (
      locaID: string,
      type: string,
      healthPlans: string[],
      locationPlans: string[]
    ) => {
      if (type === ScheduleType.place && locationPlans.length > 0) {
        const extraPlans = healthPlans.filter(
          (v: string) => locationPlans?.indexOf?.(v) === -1
        );
        if (extraPlans.length > 0) {
          return promiseDialog({ locaID, extraPlans });
        }
      }
      return Promise.resolve();
    },
    []
  );

  const handleSubmit = React.useCallback(
    (err: any, values: any, form: any) => {
      if (err) {
        return;
      }
      const offset =
        (values?.weekdayTimezoneOffset as number | undefined) ??
        moment().utcOffset() * 60;
      const formatedOffset = formatOffset(offset);
      if (values.typeHour === TypeHour.date) {
        values = {
          ...values,
          startAt: moment(
            `${values.startDate} ${values.startAt}${formatedOffset}`
          ),
          weekDay: [],
        };
      } else {
        const startDate = moment(values.startDate);
        const now = startDate.isValid()
          ? startDate.format(format.DASHUN)
          : moment().format(format.DASHUN);

        values = {
          ...values,
          startAt: moment(`${now} ${values.startAt}${formatedOffset}`),
        };
      }
      const aptyIDList: string[] = values.aptyIDList ?? [];
      const appointmentOptions = aptyIDList.map((v) => {
        return {
          aptyID: v,
          default: v === values.defaultOption,
        };
      });
      const endHour = moment(values.startAt)
        .add(values.eventDuration, "minutes")
        .local()
        .format(format.TIME24H);
      const endDate = values?.endDate
        ? moment(`${values.endDate} ${endHour}`, format.DATEHOUR)
        : undefined;
      const schedule: Partial<Schedule> = {
        type: values.type,
        price: values?.price ? formatMoneyInFloat(values.price) : 0,
        marketplaceOffer: values.marketplaceOffer,
        weekDay: values?.weekDay ?? [],
        appointmentDuration: parseInt(values.appointmentDuration),
        locaID: values?.location,
        startAt: values.startAt.utc().format(format.RFC3349),
        endAt:
          endDate && endDate.isValid()
            ? endDate.utc().format(format.RFC3349)
            : undefined,
        appointmentOptions,
        selfService: values.selfService,
        healthPlans: values.healthPlans,
        blockUnpaidAppointment: values.blockUnpaidAppointment,
        eventDuration: values.eventDuration,
        weekdayTimezoneOffset: values?.weekdayTimezoneOffset,
        procedures: values?.exprID ? [values.exprID] : undefined,
        simultaneousAppointments: values?.simultaneousAppointments,
        defaultStatus: values?.defaultStatus,
        sharedResources: values?.sharedResources,
      };
      const paymentCancelAfterMinutes = parseInt(
        values.paymentCancelAfterMinutes
      );
      if (schedule?.marketplaceOffer && !isNaN(paymentCancelAfterMinutes)) {
        schedule.cancelAfterMinutes = {
          ...schedule.cancelAfterMinutes,
          payment: paymentCancelAfterMinutes,
        };
      }
      const documentCancelAfterMinutes = parseInt(
        values.documentCancelAfterMinutes
      );
      if (!isNaN(documentCancelAfterMinutes)) {
        schedule.cancelAfterMinutes = {
          ...schedule.cancelAfterMinutes,
          document: documentCancelAfterMinutes,
        };
      }
      setLoading(true);
      dispatch(createOneSchedule(schedule as Schedule))
        .then(() => {
          openDialog(
            values.location,
            values.type,
            values.healthPlans,
            values.locationPlans
          ).finally(onSubmit);
        })
        .catch(console.warn)
        .finally(() => setLoading(false));
    },
    [openDialog, dispatch, onSubmit]
  );

  return (
    <>
      {load && <LinearProgress color="secondary" />}
      <Form
        onSubmit={handleSubmit}
        loading={loading}
        autoCompletMarketplace={allowedBank}
        initialValues={{
          cancelAfterMinutes: { payment: DefaultCancelAfterMinutes },
          type: allowedSchedule ? ScheduleType.virtual : ScheduleType.place,
          ...(pref?.options?.aptyID
            ? {
                appointmentOptions: [
                  {
                    aptyID: pref.options?.aptyID,
                    default: true,
                  },
                ],
              }
            : {}),
          ...initialValues,
        }}
      />
      {rendererDialog}
    </>
  );
};

export default ScheduleCreate;
