import * as React from "react";
import { useGetOnePatient } from "hooks/patient";

export interface PatientNameProps {
  patiID?: string;
}

const PatientName = (props: PatientNameProps) => {
  const { patiID } = props;
  const [, patient] = useGetOnePatient(patiID);

  return <span>{patient?.name}</span>;
};

export default PatientName;
