import React from "react";
import {
  CircularProgress,
  Button,
  TextField,
  TextFieldProps,
} from "@material-ui/core";

export type AutocompleteProps = {
  value?: string;
  onChange?: (a?: string) => void;
  onPress?: (a?: string) => void;
  loading?: boolean;
  buttonClass?: string;
  buttomClassLabel?: string;
  buttomLabel?: React.ReactNode;
} & Omit<TextFieldProps, "onInput" | "inputProps" | "onChange">;

const AutocompleteCEP = React.forwardRef(
  (props: AutocompleteProps, ref: React.Ref<any>) => {
    const [err, setErr] = React.useState<string>();
    const { value, buttomLabel = "Buscar" } = props;

    React.useEffect(() => {
      if ((value ?? "").length === 8 && err) {
        setErr(undefined);
      }
    }, [value, err]);

    const handlePress = (e: any) => {
      e.preventDefault();
      if ((props?.value ?? "").length !== 8) {
        setErr("O CEP deve conter 8 dígitos");
        return;
      }
      setErr(undefined);
      if (props?.onPress) {
        props?.onPress?.(props?.value);
      } else {
        props?.onChange?.(props?.value);
      }
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          ref={ref}
          type="string"
          fullWidth
          label="CEP"
          margin="normal"
          size="small"
          {...props}
          variant={(props?.variant as any) || "outlined"}
          inputProps={{
            maxLength: 8,
          }}
          onInput={(e: any) => {
            e.target.value = e.target.value.match?.(/^\d*/)?.[0] ?? "";
          }}
          onChange={(e) => {
            props?.onChange?.(e.target.value);
          }}
          autoComplete="none"
          error={props?.error || Boolean(err)}
          helperText={props?.helperText || err}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={props?.loading}
          onClick={handlePress}
          classes={{
            root: props?.buttonClass,
            label: props?.buttomClassLabel,
          }}
          endIcon={props?.loading && <CircularProgress size="0.8em" />}
        >
          {buttomLabel}
        </Button>
      </div>
    );
  }
);

export default AutocompleteCEP;
