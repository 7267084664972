import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { updateUser } from "ducks/user";
import { useUploadOneFile } from "hooks/files";
import { useGetUserMe } from "hooks/user";
import { LinearProgress } from "@material-ui/core";
import Form, { UserFormProps } from "containers/User/Form";
import { UserProfile } from "@udok/lib/api/models";
import { resetFormAndRun, onlyNumbers } from "@udok/lib/internal/util";

export type EditUserProps = {
  onSuccess: () => void;
  requiredFields?: UserFormProps["requiredFields"];
};

export const EditUser = (props: EditUserProps) => {
  const { onSuccess, requiredFields } = props;
  const [loading, setLoading] = React.useState(false);
  const [load, currentUser] = useGetUserMe();
  const [onUploadFile] = useUploadOneFile();
  const dispatch: AppDispatch = useDispatch();

  const handleUpdateUser = React.useCallback(
    async (values: any) => {
      let signatureImage = "";
      if (values?.signatureImage) {
        await onUploadFile(values?.signatureImage).then((fileName) => {
          signatureImage = fileName;
        });
      }
      const editUser = {
        ...currentUser,
        user: {
          ...currentUser?.user,
          avatar: values.avatar,
        },
        doctor: {
          ...currentUser?.doctor,
          name: values.name,
          info: {
            ...currentUser?.doctor?.info,
            about: values.about,
            publicEmail: values.publicEmail,
            publicPhone: onlyNumbers(values.publicPhone),
            subSpecialty: values.subSpecialty,
            profileColor: values.profileColor,
            curriculum: {
              ...currentUser?.doctor?.info?.curriculum,
              experiences: values.experiences,
              professionalExperience: values.professionalExperience,
              languages: values.languages,
              academicFormation: values.academicFormation,
              treatMedicalConditions: values.treatMedicalConditions,
            },
            signature: signatureImage,
            socialMedia: values.socialMedia,
          },
        },
      } as UserProfile;
      return dispatch(updateUser(editUser));
    },
    [currentUser, onUploadFile, dispatch]
  );

  const handleSubmit = React.useCallback(
    (err: any, values: any, form: any) => {
      if (err) {
        return;
      }
      setLoading(true);
      handleUpdateUser(values)
        .then(() => {
          resetFormAndRun(form, onSuccess);
        })
        .catch(console.warn)
        .finally(() => {
          setLoading(false);
        });
    },
    [handleUpdateUser, onSuccess]
  );

  return (
    <>
      {(loading || load) && <LinearProgress color="secondary" />}
      <Form
        initialValues={currentUser}
        onSubmit={handleSubmit}
        loading={loading}
        requiredFields={requiredFields}
      />
    </>
  );
};

export default EditUser;
