import React from "react";
import { Button, IconButton, Collapse } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle";
import { Theme, withStyles } from "@material-ui/core/styles";
import { LimitedResourceAlertTexts } from "@udok/lib/internal/constants";
import Icons from "@udok/lib/components/Icon";

export type ResourceAlertInlineProps = {
  open: boolean;
  close: () => void;
  openOffers?: () => void;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
};

const Alert = withStyles((theme: Theme) => ({
  icon: {
    opacity: 1,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  filledInfo: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 46,
    },
  },
  message: {
    flex: 1,
    whiteSpace: "break-spaces",
  },
  action: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      position: "absolute",
      bottom: theme.spacing(1),
      left: "40%",
    },
  },
}))((props: AlertProps) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
));

const ResourceAlertInline = (props: ResourceAlertInlineProps) => {
  const {
    open,
    close,
    openOffers,
    icon = false,
    title = LimitedResourceAlertTexts.title,
    description = LimitedResourceAlertTexts.description,
  } = props;

  const handleClose = React.useCallback(() => {
    close();
  }, [close]);

  const handleViewOffers = React.useCallback(() => {
    openOffers?.();
  }, [openOffers]);

  return (
    <Collapse in={open}>
      <Alert
        icon={icon}
        severity="info"
        action={
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleViewOffers}
          >
            {LimitedResourceAlertTexts.buttonLabel}
          </Button>
        }
      >
        <IconButton
          style={{ position: "absolute", top: 0, right: 0 }}
          size="small"
          color="inherit"
          onClick={handleClose}
        >
          <Icons.CloseRounded fontSize="small" color="inherit" />
        </IconButton>
        <MuiAlertTitle>{title}</MuiAlertTitle>
        {description}
      </Alert>
    </Collapse>
  );
};

export default ResourceAlertInline;
