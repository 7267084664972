import * as React from "react";
import {
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { ScheduleType, PlanFeaturesPermissions } from "@udok/lib/api/models";
import ResourceAlertPopover from "containers/LimitedResourceAlert/Popover";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";

export type InputScheduleTypeProps = {
  disabled?: boolean;
  validatePermission?: boolean;
  onChange?: (v: string) => void;
} & Omit<RadioGroupProps, "onChange">;

const [rendererResourceAlert, promiseResourceAlert] =
  createModal(ResourceAlertPopover);
const InputScheduleType = React.forwardRef(
  (props: InputScheduleTypeProps, ref: React.Ref<any>) => {
    const { disabled, validatePermission, onChange, ...others } = props;
    const [activeResourceAlert, setActive] = React.useState(true);

    const handleChange = React.useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (
          validatePermission &&
          activeResourceAlert &&
          value !== ScheduleType.place
        ) {
          await promiseResourceAlert({
            anchorEl: event.target,
            feature: PlanFeaturesPermissions.UnlimitedSchedule,
          }).then(() => setActive(false));
        }
        onChange?.(value);
      },
      [activeResourceAlert, validatePermission, onChange]
    );

    return (
      <>
        <RadioGroup
          ref={ref}
          style={{ marginBottom: 5 }}
          onChange={handleChange}
          {...others}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <FormControlLabel
              disabled={disabled}
              value={ScheduleType.virtual}
              control={<Radio color="primary" />}
              label="Consulta virtual"
            />
            <FormControlLabel
              disabled={disabled}
              value={ScheduleType.place}
              control={<Radio color="primary" />}
              label="Consulta presencial"
            />
            <FormControlLabel
              disabled={disabled}
              value={ScheduleType.examProcedure}
              control={<Radio color="primary" />}
              label="Exames e procedimentos"
            />
          </div>
        </RadioGroup>
        {rendererResourceAlert}
      </>
    );
  }
);

export default InputScheduleType;
