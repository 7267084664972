import React from "react";
import { connect } from "react-redux";
import { AppDispatch } from "ducks/state";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ActiveChatUserContext } from "@udok/lib/components/UI/UIContext";

import DialogContent from "@material-ui/core/DialogContent";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
//@ts-ignore
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { AppointmentNote } from "containers/DoctorNote/Editor";
import Title from "components/Dialog/Title";
import TitleBrief from "./TitleBrief";
import { Typography, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

type ResizeCallbackData = {
  node: HTMLElement;
  size: { width: number; height: number };
  handle: ResizeHandleAxis;
};
type ResizeHandleAxis = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";

const LOCALSTORAGEKEY = "appointment-floating-dialog-pos";

function PaperComponent(props: PaperProps & { handle: string }) {
  var objNode = document.createElement("div");
  objNode.style.width = "100vw";
  objNode.style.height = "100vh";
  document.body.appendChild(objNode);
  var vw = objNode.offsetWidth;
  var vh = objNode.offsetHeight;
  document.body.removeChild(objNode);
  const spos = localStorage.getItem(LOCALSTORAGEKEY)?.split(",");
  const init = {
    w: parseInt(spos?.[2] ?? `${vw / 2}`),
    h: parseInt(spos?.[3] ?? `${250}`),
  };
  const pos = {
    x: parseInt(spos?.[0] ?? `${vw / 2 - init.w}`),
    y: parseInt(spos?.[1] ?? `${vh / 2 - init.h}`),
  };

  const handleResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    const spos = localStorage.getItem(LOCALSTORAGEKEY)?.split(",");
    localStorage.setItem(
      LOCALSTORAGEKEY,
      [
        spos?.[0] ?? "100",
        spos?.[1] ?? "100",
        data.size.width,
        data.size.height,
      ].join(",")
    );
  };
  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    const spos = localStorage.getItem(LOCALSTORAGEKEY)?.split(",");
    localStorage.setItem(
      LOCALSTORAGEKEY,
      [data.x, data.y, spos?.[2] ?? "360", spos?.[3] ?? "250"].join(",")
    );
  };

  return (
    <Draggable
      defaultPosition={pos}
      handle={props.handle}
      bounds={{
        left: 0,
        top: 0,
        right: vw * 0.8,
        bottom: vh * 0.8,
      }}
      cancel={'[class*="MuiDialogContent-root"]'}
      onDrag={handleDrag}
    >
      <Paper {...props}>
        <ResizableBox
          width={init.w}
          height={init.h}
          minConstraints={[360, 250]}
          maxConstraints={[vw / 2, vh / 2]}
          axis="both"
          resizeHandles={["se"]}
          onResize={handleResize}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {props.children}
          </div>
        </ResizableBox>
      </Paper>
    </Draggable>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1000,
      position: "absolute",
      top: 0,
      left: 0,
    },
    content: { overflow: "auto", marginBottom: theme.spacing(3) },
    returnBtn: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      height: "min-content",
      width: 200,
      backgroundColor: theme.palette.primary.lightest,
      color: theme.palette.primary.main,
    },
    returnBtnActive: {
      display: "none",
    },
  })
);

export type AppointmentChatProps = {
  id: string;
} & ReturnType<typeof mapDispatchToProps>;

const AppointmentChat = (props: AppointmentChatProps) => {
  const { id } = props;
  const { pinnedAppointment, setPinnedAppointment } = React.useContext(
    ActiveChatUserContext
  );
  const classes = useStyles();
  if (!pinnedAppointment) {
    return null;
  }
  return (
    <>
      <PaperComponent
        handle={"#" + id}
        aria-labelledby={id}
        className={classes.root}
      >
        <Title
          style={{ cursor: "move", justifyContent: "space-between" }}
          titleTypographyProps={{
            style: {
              display: "flex",
              justifyContent: "space-between",
            },
          }}
          id={id}
          onClose={() => {
            setPinnedAppointment?.(null);
          }}
        >
          <TitleBrief appoID={pinnedAppointment!.appoID} />
          <Button
            component={NavLink}
            to={`/admin/appointments/${pinnedAppointment!.appoID}`}
            className={classes.returnBtn}
            activeClassName={classes.returnBtnActive}
            fullWidth={false}
          >
            Voltar ao agendamento
          </Button>
        </Title>
        <DialogContent className={classes.content}>
          <Typography variant="h6" color="textSecondary">
            <b>Anotações</b>
          </Typography>
          <AppointmentNote
            patiID={pinnedAppointment!.patiID}
            appoID={pinnedAppointment!.appoID}
          />
        </DialogContent>
      </PaperComponent>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(() => ({}), mapDispatchToProps)(AppointmentChat);
