import * as React from "react";
import DialogSchedulePlans from "containers/SchedulePlans/DialogSchedulePlans";

export interface Props {
  locaID: string;
  extraPlans: string[];
  open: boolean;
  close: (p?: any) => void;
}

const SchedulePlansContainer = (props: Props) => {
  const { locaID, extraPlans, open, close } = props;

  return (
    <div>
      <DialogSchedulePlans
        key={locaID}
        open={open}
        close={close}
        locaID={locaID}
        extraPlans={extraPlans}
      />
    </div>
  );
};

export default SchedulePlansContainer;
