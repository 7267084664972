import React from "react";
import { useSelector } from "react-redux";
import { CreditCard } from "@udok/lib/api/models";
import { Preferences } from "@udok/lib/api/models";
import { creditCardsView } from "ducks/billing";
import { getOneDoctorPreferences } from "ducks/doctorPreferences";
import { CreditCardPicker } from "containers/Finances/CreditCard/CreditCardPicker";

export type CreditCardSelectorProps = {
  selectedCard?: CreditCard;
  onSelect: (card?: CreditCard) => void;
};

const CreditCardSelector = (props: CreditCardSelectorProps) => {
  const { selectedCard, onSelect } = props;
  const [autoSelect, setAutoSelect] = React.useState(!selectedCard);
  const getPerf = React.useCallback(
    getOneDoctorPreferences({ prefID: Preferences.financesDefaultCreditCard }),
    []
  );

  const { list } = useSelector(creditCardsView);
  const { location: pref } = useSelector(getPerf);
  const preferredCardID = pref?.options?.crcaID;

  const handleSelect = React.useCallback(
    (card?: string | CreditCard) => {
      if (typeof card !== "string") {
        onSelect(card);
      }
    },
    [onSelect]
  );

  React.useEffect(() => {
    if (list.length > 0 && autoSelect) {
      const card =
        list.find((c) => c.crcaID === preferredCardID) ??
        list?.[list.length - 1];
      onSelect(card);
    }
    setAutoSelect(false);
  }, [autoSelect, list, preferredCardID, onSelect]);

  return (
    <CreditCardPicker
      list={list}
      preferredCardID={preferredCardID}
      value={selectedCard}
      returnType="CreditCard"
      onSelected={handleSelect}
      formProps={{ initialValues: { isDefault: true } }}
    />
  );
};

export default CreditCardSelector;
