import { RootState } from "ducks/state";
import { hen, Hen } from "@udok/lib/internal/store";
import { UNAUTHORIZED } from "./auth";

export type ViseIDCache = { userID: string; viseID: string; expiresAt: string };
export type InitialState = {
  viseIDCache: { [userID: string]: ViseIDCache };
};

//Reducers
const initialState: InitialState = {
  viseIDCache: {},
};

class LocalSlice extends Hen<InitialState> {
  updateViseIDCache(v: ViseIDCache) {
    this.state.viseIDCache[v.userID] = v;
  }
}

export const [Reducer, actions] = hen(new LocalSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.local;
export const cacheSelector = (state: RootState) =>
  mainSelector(state).viseIDCache;
