import React from "react";
import { TextField, TextFieldProps, InputAdornment } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SocialMedia } from "@udok/lib/api/models";
import Icons from "@udok/lib/components/Icon";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(1),
      "& > div": {
        marginBottom: theme.spacing(0.5),
      },
    },
  })
);

export type SocialMediaInputPorps = {
  value?: SocialMedia;
  onChange?: (val: SocialMedia) => void;
  className?: string;
};

const SocialMediaInput = React.forwardRef(
  (props: SocialMediaInputPorps, ref: React.Ref<any>) => {
    const { value, onChange, className } = props;
    const [cv, setCv] = React.useState<SocialMedia>();
    const classes = useStyles();

    React.useEffect(() => {
      if (value) {
        setCv(value);
      }
    }, [value]);

    const handleChange = React.useCallback(
      (val: SocialMedia) => {
        setCv((current) => {
          const newValue = { ...current, ...val };
          onChange?.(newValue);
          return newValue;
        });
      },
      [setCv, onChange]
    );

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <MediaField
          label="Facebook"
          value={cv?.facebook}
          icon={<Icons.Facebook color="action" />}
          onChange={(v) => handleChange({ facebook: v })}
        />
        <MediaField
          label="Instagram"
          value={cv?.instagram}
          icon={<Icons.Instagram color="action" />}
          onChange={(v) => handleChange({ instagram: v })}
        />
        <MediaField
          label="LinkedIn"
          value={cv?.linkedIn}
          icon={<Icons.LinkedIn color="action" />}
          onChange={(v) => handleChange({ linkedIn: v })}
        />
        <MediaField
          label="Twitter"
          value={cv?.twitter}
          icon={<Icons.Twitter color="action" />}
          onChange={(v) => handleChange({ twitter: v })}
        />
        <MediaField
          label="YouTube"
          value={cv?.youTube}
          icon={<Icons.YouTube color="action" />}
          onChange={(v) => handleChange({ youTube: v })}
        />
      </div>
    );
  }
);

type MediaFieldProps = {
  icon: React.ReactNode;
  value?: string;
  onChange?: (val: string) => void;
} & Omit<TextFieldProps, "onChange" | "value" | "InputProps" | "variant">;
var ReplaceURL = new RegExp(/(https:\/\/[^\/]*)(.*?)(\?.*)*$/gm);
const MediaField = (props: MediaFieldProps) => {
  const { icon, value, onChange, ...others } = props;

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.split(ReplaceURL);
      if (val.length === 1) {
        onChange?.(val[0]);
      } else {
        onChange?.(val?.[2] ?? "");
      }
    },
    [onChange]
  );

  return (
    <div>
      <TextField
        variant="outlined"
        margin="dense"
        value={value}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }}
        {...others}
      />
    </div>
  );
};

export default SocialMediaInput;
