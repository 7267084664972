import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { EventAlert, FilterEventAlert } from "@udok/lib/api/models";
import { fetchEventAlerts, addEventAlertReadDate } from "@udok/lib/api/events";
import { getToken, UNAUTHORIZED } from "ducks/auth";

export type InitialState = {
  eventAlerts: { [notiID: string]: EventAlert | undefined };
};

// Reducers
const initialState: InitialState = {
  eventAlerts: {},
};

class Events extends Hen<InitialState> {
  eventAlertsLoaded(eves: EventAlert[]) {
    eves.forEach((eve) => {
      this.state.eventAlerts[eve.notiID] = eve;
    });
  }
  eventAlertLoaded(eve: EventAlert) {
    this.state.eventAlerts[eve.notiID] = eve;
  }
}

export const [Reducer, actions] = hen(new Events(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const eventAlertsSelector = (state: RootState) => state.events.eventAlerts;

export const getEventAlertsList = createSelector(
  [eventAlertsSelector],
  (eventAlerts) => {
    return {
      alerts: Object.keys(eventAlerts)
        .map((notiID) => eventAlerts[notiID])
        .filter((a) => !!a) as EventAlert[],
    };
  }
);

export const getAppointmentEvents = (props: { appoID?: string }) =>
  createSelector([eventAlertsSelector], (eventAlerts) => {
    return {
      alerts: Object.keys(eventAlerts)
        .map((notiID) => eventAlerts[notiID])
        .filter(
          (eve) => !!eve && eve?.data?.appoID === props?.appoID
        ) as EventAlert[],
    };
  });

// Actions
export function loadEventAlerts(
  filter?: FilterEventAlert
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchEventAlerts(apiToken, filter)
      .then((r) => {
        dispatch(actions.eventAlertsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function readEventAlert(notiID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return addEventAlertReadDate(apiToken, notiID)
      .then((r) => {
        dispatch(actions.eventAlertLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function readEventAlertList(notiIDs: string[]): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return Promise.all(
      notiIDs.map((notiID) => addEventAlertReadDate(apiToken, notiID))
    )
      .then((r) => {
        dispatch(actions.eventAlertsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
