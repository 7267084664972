import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  KYCLink,
  MarketplaceRecipient,
  MarketplaceRecipienUpdate,
  MarketplaceRecipientFilter,
} from "@udok/lib/api/models";
import {
  fetchKYCLink,
  updateRecipient,
  createRecipient,
  fetchRecipients,
  fetchRecipient,
  deleteRecipient,
} from "@udok/lib/api/marketplace";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";

export type InitialState = {
  recipientByID: { [mareID: string]: MarketplaceRecipient | undefined };
  kycLinkByID: { [mareID: string]: KYCLink | undefined };
};

// Reducers
const initialState: InitialState = {
  recipientByID: {},
  kycLinkByID: {},
};

class MarketplaceRecipientSlice extends Hen<InitialState> {
  recipientsLoaded(notes: MarketplaceRecipient[]) {
    notes.forEach((note) => {
      this.state.recipientByID[note.mareID] = note;
    });
  }

  recipientRemoved(mareID: string) {
    this.state.recipientByID[mareID] = undefined;
  }

  kycLinkLoaded(mareID: string, link: KYCLink) {
    this.state.kycLinkByID[mareID] = link;
  }
}

export const [Reducer, actions] = hen(
  new MarketplaceRecipientSlice(initialState),
  {
    [UNAUTHORIZED]: () => {
      return initialState;
    },
  }
);

// Selectors
const mainSelector = (state: RootState) => state.marketplace;
const kycLinkByIDSelector = (state: RootState) => state.marketplace.kycLinkByID;

export const recipientsView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.recipientByID)
      .map((mareID) => state.recipientByID[mareID])
      .filter((a) => a !== undefined) as MarketplaceRecipient[],
  };
});

export const getRecipient = (props: { mareID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      recipient: state.recipientByID[props.mareID],
    };
  });

export const getRecipientKYCLink = (props: { mareID: string }) =>
  createSelector(kycLinkByIDSelector, (kycLinkByID) => {
    return {
      link: kycLinkByID[props.mareID],
    };
  });

// Actions
export function createOneMarketplaceRecipient(
  note: MarketplaceRecipient
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createRecipient(apiToken, note)
      .then((r) => {
        dispatch(actions.recipientsLoaded([r]));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Salvo com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadMarketplaceRecipients(
  filter?: MarketplaceRecipientFilter
): AppThunk<Promise<MarketplaceRecipient[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchRecipients(apiToken, filter)
      .then((r) => {
        dispatch(actions.recipientsLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneMarketplaceRecipient(
  mareID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchRecipient(apiToken, mareID)
      .then((r) => {
        dispatch(actions.recipientsLoaded([r]));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedMarketplaceRecipient(
  mareID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const mpr = state.marketplace.recipientByID?.[mareID];
    if (Boolean(mpr)) {
      return Promise.resolve();
    }

    return dispatch(loadOneMarketplaceRecipient(mareID));
  };
}

export function removeMarketplaceRecipient(
  mareID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteRecipient(apiToken, mareID)
      .then((r) => {
        dispatch(actions.recipientRemoved(r.mareID));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Removido com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateMarketplaceRecipient(
  mareID: string,
  mpr: MarketplaceRecipienUpdate
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateRecipient(apiToken, mareID, mpr)
      .then((r) => {
        dispatch(actions.recipientsLoaded([r]));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Atualizado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchRecipientKYCLink(
  mareID: string
): AppThunk<Promise<KYCLink>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchKYCLink(apiToken, mareID)
      .then((r) => {
        dispatch(actions.kycLinkLoaded(mareID, r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedKYCLink(mareID: string): AppThunk<Promise<KYCLink>> {
  return async (dispatch, getState) => {
    const state = getState();
    const link = state.marketplace.kycLinkByID?.[mareID];
    const expiresAt = moment(link?.expires_at);
    const exist =
      expiresAt.isValid() &&
      expiresAt.isBefore(moment().subtract(5, "minutes"));
    if (exist) {
      return Promise.resolve(link as KYCLink);
    }

    return dispatch(fetchRecipientKYCLink(mareID));
  };
}
