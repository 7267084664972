import React from "react";
import { BillingInformation } from "@udok/lib/api/models";
import { Button } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import FormAddress from "containers/Finances/BillingInformation/FormAddress";
// @ts-ignore
import createDOMForm from "rc-form/lib/createDOMForm";
// @ts-ignore
import { formShape } from "rc-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
      marginTop: theme.spacing(2),
    },
  })
);

export type EditSubscriptionAddressProps = {
  onSubmit: (a: BillingInformation["billingAddress"]) => void;
  initialValues?: Partial<BillingInformation["billingAddress"]>;
  form?: formShape;
};

const EditSubscriptionAddress = (props: EditSubscriptionAddressProps) => {
  const { form, initialValues, onSubmit } = props;
  const theme = useTheme();
  const classes = useStyles();

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      form.validateFieldsAndScroll(
        { scroll: { offsetTop: theme.spacing(10) } },
        (err: any, values: any) => {
          if (err) {
            return;
          }
          const val: BillingInformation["billingAddress"] = {
            name: values.addressName,
            zipcode: values.addressZipcode,
            street: values.addressStreet,
            neighborhood: values.addressNeighborhood,
            street_number: values.addressStreetNumber,
          };
          onSubmit(val);
        }
      );
    },
    [theme, form, onSubmit]
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormAddress initialValues={initialValues} form={form} />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" type="submit">
          <span>Continuar</span>
        </Button>
      </div>
    </form>
  );
};

export default createDOMForm()(
  EditSubscriptionAddress
) as typeof EditSubscriptionAddress;
