import { useCallback } from "react";
import html2canvas from "html2canvas";
//@ts-ignore
import { jsPDF } from "jspdf";

export const WidthH4 = 900;
export const HeightH4 = 1070;

export const useGenerateElementPDF = () => {
  const generateElementPDF = useCallback(
    (element: HTMLElement | null, fileName: string) => {
      return new Promise<void>((resolve, reject) => {
        if (!element) {
          reject("element not found");
          return;
        }
        html2canvas(element)
          .then((canvas) => {
            const pdf = new jsPDF("p", "pt", "letter");
            for (let i = 0; i <= element.clientHeight / HeightH4; i++) {
              const srcImg = canvas;
              const sX = 0;
              const sY = HeightH4 * i;
              const sWidth = WidthH4;
              const sHeight = HeightH4;
              const dX = 0;
              const dY = 0;
              const dWidth = WidthH4;
              const dHeight = HeightH4;

              const onePageCanvas = document.createElement("canvas");
              onePageCanvas.setAttribute("width", `${WidthH4}`);
              onePageCanvas.setAttribute("height", `${HeightH4}`);
              const ctx = onePageCanvas.getContext("2d");
              if (!ctx) return;
              ctx.drawImage(
                srcImg,
                sX,
                sY,
                sWidth,
                sHeight,
                dX,
                dY,
                dWidth,
                dHeight
              );

              const canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
              const width = onePageCanvas.width;
              const height = onePageCanvas.clientHeight;

              if (i > 0) {
                pdf.addPage([612, 791]);
              }

              pdf.setPage(i + 1);
              pdf.addImage(
                canvasDataURL,
                "PNG",
                20,
                40,
                width * 0.62,
                height * 0.62
              );
            }

            pdf.save(fileName || "");
            resolve();
          })
          .catch(reject);
      });
    },
    []
  );

  return [generateElementPDF] as [typeof generateElementPDF];
};
