import { createSelector } from "reselect";
import { hen, Hen } from "@udok/lib/internal/store";
import { RootState } from "ducks/state";
import Websocket from "@udok/lib/internal/socket/websocket";
import { UNAUTHORIZED } from "ducks/auth";
import { ViewerRealtimeState } from "@udok/lib/api/models";

import moment from "moment";
moment.locale("pt-br");

export type Error = any;

export const WS = {
  Idle: "idle",
  Connected: "connected",
  Disconnected: "disconnected",
  Connecting: "connecting",
  Sending: "sending",
};

export const WSsocket = {
  0: WS.Idle,
  1: WS.Connected,
  2: WS.Disconnected,
  3: WS.Connecting,
  4: WS.Sending,
};

export enum ActionTypes {
  Connect = "WSConnect",
  Connected = "WSConnected",
  Receive = "WSReceive",
  Disconnect = "WSDisconnect",
  Send = "Wssand",
  Reset = "socket.reset",
  Logout = "Logout",
  PartialStateUpdate = "partialStateUpdate",
  Echo = "echo",
}

export type Actions = {
  Connect: { type: ActionTypes.Connect };
  Connected: { type: ActionTypes.Connected };
  Send: { type: ActionTypes.Send; payload: any };
  Receive: { type: ActionTypes.Receive };
  Disconnect: { type: ActionTypes.Disconnect };
  Reset: { type: ActionTypes.Reset };
  PartialStateUpdate: {
    type: ActionTypes.PartialStateUpdate;
    payload: Partial<ViewerRealtimeState>;
  };
  Logout: { type: ActionTypes.Logout };
};

export type InitialState = {
  realtimeState: { [imcsID: string]: Partial<ViewerRealtimeState> | undefined };
  clientsStatus: Array<any>;
  status: string;
  error?: Error;
  granted?: boolean;
};

//reducers
const initialState: InitialState = {
  realtimeState: {},
  clientsStatus: [],
  status: WS.Disconnected,
  error: undefined,
  granted: undefined,
};

let socket: any = null;
class SocketSlice extends Hen<InitialState> {
  socketConnect() {
    this.state.status = WS.Connecting;
  }
  socketConnected(event: any) {
    this.state.status = WS.Connected;
  }
  socketStatusChange(s: string) {
    this.state.status = s;
  }
  socketDisconnect(event: any) {
    this.state.status = WS.Disconnected;
  }
  socketReceived(event: any) {
    this.state.status = WS.Connected;
  }
  partialStateUpdate(payload: Partial<ViewerRealtimeState>) {
    if (payload?.imcsID) {
      const current = this.state.realtimeState[payload.imcsID];
      this.state.realtimeState[payload.imcsID] = { ...current, ...payload };
    }
  }
}
//Reducers
export const extraReducers = {
  [ActionTypes.Logout](state: InitialState, a: Actions["Logout"]) {
    state = initialState;
    return state;
  },
  [UNAUTHORIZED]: (state: InitialState) => {
    state = initialState;
    return state;
  },
};

export const [Reducer, actions] = hen(
  new SocketSlice(initialState),
  extraReducers
);

//Selectors
const mainSelector = (state: RootState) => state.realtime;
const realtimeState = (state: RootState) => state.realtime.realtimeState;

export const getStatusSocket = createSelector(mainSelector, (state) => {
  return { statusSocket: state.status };
});

export const getStatus = createSelector(mainSelector, (state) => ({
  status: state.status,
}));

export const getRealtimeState = (props: { imcsID: string }) =>
  createSelector(realtimeState, (state) => ({
    realtimeState: state[props.imcsID],
  }));

export const listRealtimeState = createSelector(realtimeState, (state) => ({
  list: Object.keys(state).map((imcsID) => state[imcsID]),
}));

//Actions
export function disconnectRealtime() {
  try {
    socket.close();
  } catch (e) {
    console.warn("close err", e);
  }
  socket = null;
  return {
    type: ActionTypes.Logout,
  };
}

export function reset(type: any) {
  return {
    type: ActionTypes.Reset,
    payload: { type },
  };
}

function startSession() {
  const action = {
    type: "init",
    payload: {},
  };

  socket.send(action);
}

function resolver(Type: string, payload: any) {
  const actionMap: { [key: string]: any } = {
    [ActionTypes.PartialStateUpdate]: actions.partialStateUpdate,
    [ActionTypes.Echo]: () => {},
  };
  const anyFunction = () => {};
  return actionMap?.[Type]?.(payload) ?? anyFunction;
}

export function connectRealtime() {
  return (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const apiToken = state.auth.token.raw;
    socket?.close?.();
    socket = new Websocket(
      apiToken,
      process.env.REACT_APP_REALTIME_PATH as string
    );
    return new Promise<void>((resolve, reject) => {
      socket.connect();

      socket.onopen = (e: any) => {
        startSession();
        dispatch(actions.socketConnected(e));
        resolve();
      };
      socket.onstatus = (e: 0 | 1 | 2 | 3 | 4) => {
        dispatch(actions.socketStatusChange(WSsocket[e]));
      };

      socket.onmessage = (e: any) => {
        let fromServer: any = {};
        try {
          fromServer = JSON.parse(e.data);
        } catch (e) {
          console.warn(e);
          return;
        }
        dispatch(actions.socketReceived(e));
        dispatch(resolver(fromServer.type, fromServer.payload));
      };

      socket.onerror = (e: any) => {
        reject(e);
      };

      socket.onclose = (e: any) => {
        dispatch(actions.socketDisconnect(e));
      };
    }).catch((e) => {
      console.warn("socket error", e);
    });
  };
}
