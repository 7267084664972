import React from "react";
import { LinearProgress } from "@material-ui/core";
import { useActiveSubscriptionView } from "hooks/user";
import { ActiveSubscriptionStatus } from "@udok/lib/api/models";
import SubscriptionDialog from "containers/Marketing/SubscriptionDialog";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import OnboardingStepper from "containers/OnboardingStepper/ConnectedStepper";

const ExcludedPaths = ["/admin/finances/subscription/change"];
const SubscriptionAlert = ({
  open,
  close,
  suofID,
}: {
  open: boolean;
  close: (r: boolean) => void;
  suofID?: string;
}) => {
  const pathname = window.location.pathname;

  const handleSuccess = React.useCallback(() => {
    close(true);
  }, [close]);

  React.useEffect(() => {
    if (open && ExcludedPaths.indexOf(pathname) !== -1) {
      close(false);
    }
  }, [open, close, pathname]);

  return (
    <SubscriptionDialog
      open={open}
      suofID={suofID}
      onSuccess={handleSuccess}
      offerViewSettings={{
        title: "Você ainda não possuí um plano Udok.",
        description: "Escolha um de nossos planos abaixo antes de prosseguir.",
      }}
    />
  );
};

type ActiveSubLoaderProps = {
  suofID?: string;
  currentPath: string;
};
const [rendererAlert, promiseAlert] = createModal(SubscriptionAlert);
const [rendererStepper, promiseStepper] = createModal(OnboardingStepper);
const ActiveSubLoader = React.memo((props: ActiveSubLoaderProps) => {
  const { suofID, currentPath } = props;
  const [loading, subscription] = useActiveSubscriptionView();
  const isValidScreen = ExcludedPaths.indexOf(currentPath) === -1;

  React.useEffect(() => {
    if (
      isValidScreen &&
      subscription?.status === ActiveSubscriptionStatus.invalid
    ) {
      setTimeout(() => {
        promiseAlert({ suofID }).then((r: boolean) => {
          if (r) {
            promiseStepper();
          }
        });
      }, 2000);
    }
  }, [suofID, subscription, isValidScreen]);

  return (
    <>
      {loading && <LinearProgress color="primary" />}
      {rendererAlert}
      {rendererStepper}
    </>
  );
});

export default ActiveSubLoader;
