import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  SharedResource,
  SharedResourceFilter,
  SharedResourceForm,
} from "@udok/lib/api/models";
import {
  createSharedResource,
  deleteSharedResource,
  updateSharedResource,
  fetchSharedResource,
  fetchSharedResources,
} from "@udok/lib/api/sharedResource";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  sharedResourceByID: { [shreID: string]: SharedResource };
  scheduleEditorShreIDs: string[];
};

//Reducers
const initialState: InitialState = {
  sharedResourceByID: {},
  scheduleEditorShreIDs: [],
};

class SharedResources extends Hen<InitialState> {
  sharedResourceLoaded(m: SharedResource) {
    this.state.sharedResourceByID[m.shreID] = m;
  }
  sharedResourcesLoaded(ms: SharedResource[]) {
    ms.forEach((m) => {
      this.state.sharedResourceByID[m.shreID] = m;
    });
  }
  sharedResourceRemoved(shreID: string) {
    delete this.state.sharedResourceByID[shreID];
  }
  scheduleEditorLoaded(ms: SharedResource[]) {
    ms.forEach((m) => {
      this.state.sharedResourceByID[m.shreID] = m;
    });
    this.state.scheduleEditorShreIDs = ms.map((m) => m.shreID);
  }
}

export const [Reducer, actions] = hen(new SharedResources(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

//Selectors
const mainSelector = (state: RootState) => state.sharedResource;

export const getOneSharedResource = (props: { shreID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      sharedResource: state.sharedResourceByID[props.shreID],
    };
  });

export const getListSharedResource = createSelector(
  [mainSelector, (state) => state.auth.token.payload.doctID],
  (state, doctID) => {
    const list = Object.values(state.sharedResourceByID)
      .filter((s) => s?.deletedAt === null && s.doctID === doctID)
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    return {
      list: list,
    };
  }
);

export const sharedResourceListView = createSelector(
  [mainSelector],
  (state) => {
    const shared = state.scheduleEditorShreIDs
      .map((shreID) => state.sharedResourceByID[String(shreID)]!)
      .filter((sharedResource) => sharedResource !== undefined)
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    return {
      list: shared,
    };
  }
);

//Actions
export function createNewSharedResource(
  form: SharedResourceForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createSharedResource(apiToken, form)
      .then((r) => {
        dispatch(actions.sharedResourceLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Recurso compartilhado criado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadSharedResources(
  filter?: SharedResourceFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const doctID = t.token.payload.doctID;
    const apiToken = "Bearer " + t.token.raw;
    const fil: SharedResourceFilter = {
      ...filter,
      doctID: doctID,
    };

    return fetchSharedResources(apiToken, fil)
      .then((r) => {
        dispatch(actions.sharedResourcesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneSharedResource(shreID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchSharedResource(apiToken, shreID)
      .then((r) => {
        dispatch(actions.sharedResourceLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedSharedResource(
  shreID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const postExist = Boolean(state.sharedResource.sharedResourceByID[shreID]);
    if (postExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneSharedResource(shreID));
  };
}

export function changeSharedResource(
  form: SharedResourceForm & { shreID: string }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateSharedResource(apiToken, form)
      .then((r) => {
        dispatch(actions.sharedResourceLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Recurso compartilhado atualizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeSharedResource(shreID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteSharedResource(apiToken, shreID)
      .then((r) => {
        dispatch(actions.sharedResourceRemoved(r.shreID));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Recurso compartilhado removido com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadScheduleEditorResources(
  filter?: SharedResourceFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const fil: SharedResourceFilter = {
      ...filter,
    };

    return fetchSharedResources(apiToken, fil)
      .then((r) => {
        dispatch(actions.scheduleEditorLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
