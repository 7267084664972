import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { getRealtimeState, listRealtimeState } from "ducks/realtime";
import { getUserMe } from "ducks/user";
import {
  viewImmediateCareSession,
  getOneImmediateCareSession,
  loadCachedImmediateCareByImcsID,
} from "ducks/immediateCare";
import { Caregiver } from "@udok/lib/api/models";

export const useOneImmediateCareSession = (imcsID: string) => {
  const getState = React.useCallback(getRealtimeState({ imcsID }), [imcsID]);
  const getSession = React.useCallback(getOneImmediateCareSession({ imcsID }), [
    imcsID,
  ]);
  const { realtimeState } = useSelector(getState);
  const { immediateCare } = useSelector(getSession);
  const { currentUser } = useSelector(getUserMe);

  return [realtimeState, immediateCare, currentUser] as [
    typeof realtimeState,
    typeof immediateCare,
    typeof currentUser
  ];
};

export const useActiveImmediateCareSession = () => {
  const dispatch: AppDispatch = useDispatch();
  const [first, setFirst] = React.useState(true);
  const { list } = useSelector(listRealtimeState);
  const { currentUser } = useSelector(getUserMe);

  const [activeSession, currentDoctor] = React.useMemo(() => {
    let currentDoctor: Caregiver | undefined;

    const activeSession = list.find((session) => {
      const caregivers = session?.caregivers ?? [];
      const doctors = caregivers
        .map((cg) => cg.doctID)
        .filter((doctID) => !!doctID);
      const index = doctors.indexOf(currentUser?.doctor?.doctID ?? "");
      currentDoctor = caregivers?.[index];

      return index !== -1;
    });

    return [activeSession, currentDoctor];
  }, [list, currentUser]);

  const getSession = React.useCallback(
    getOneImmediateCareSession({ imcsID: activeSession?.imcsID ?? "" }),
    [activeSession]
  );
  const { immediateCare } = useSelector(getSession);

  React.useEffect(() => {
    const imcsID = activeSession?.imcsID;
    if (imcsID && first) {
      setFirst(false);
      Promise.all([
        dispatch(viewImmediateCareSession(imcsID)),
        dispatch(loadCachedImmediateCareByImcsID(imcsID)),
      ]);
    }
  }, [first, activeSession, dispatch]);

  return [immediateCare, activeSession, currentDoctor] as [
    typeof immediateCare,
    typeof activeSession,
    typeof currentDoctor
  ];
};
