import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import HelpPopover from "@udok/lib/components/HelpPopover";
import { makeStyles } from "@material-ui/core/styles";
import Illustrations from "@udok/lib/components/Illustrations";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  textBody: { flex: 3 },
  body: {
    display: "flex",
    flexDirection: "row",
  },
});

function CreditCardCVVPopover() {
  const classes = useStyles();
  return (
    <HelpPopover title="O que é isso?">
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Udok Ajuda
          </Typography>
          <Typography variant="h5" component="h2">
            O que é o Código de Verificação de Cartão (CVC)?
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Valor de Verificação de Cartão (CVV), Código de Segurança de Cartão
            ou Código de Segurança Pessoal
          </Typography>
          <div className={classes.body}>
            <Typography
              variant="body2"
              component="p"
              className={classes.textBody}
            >
              O Código de Verificação de Cartão, ou CVC, é o código impresso no
              verso do cartão de débito ou crédito.
              <br />
              Nos cartões American Express (AMEX), geralmente é um código de
              quatro dígitos na frente do cartão
            </Typography>
            <Illustrations.CreditCardCVV height={100} width={150} />
          </div>
        </CardContent>
      </Card>
    </HelpPopover>
  );
}

export default CreditCardCVVPopover;
