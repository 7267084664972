import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { createOneDoctorNote, changeDoctorNote } from "ducks/doctorNote";
import { useGetAppointmentNote, useGetPatientNote } from "hooks/doctorNote";
import { DoctorNote } from "@udok/lib/api/models";
import Form, {
  AnnotationFormProps,
  AnnotationFormRef,
} from "containers/DoctorNote/Form";
import { LinearProgress } from "@material-ui/core";
import { resetFormAndRun } from "@udok/lib/internal/util";

export type AnnotationEditProps = {
  appoID?: string;
  patiID?: string;
  currentNote?: DoctorNote;
  loading?: boolean;
  extended?: boolean;
  activeAutoSave?: boolean;
  titleProps?: AnnotationFormProps["titleProps"];
  formRef?: React.Ref<AnnotationFormRef>;
  onExtendedToggle?: () => void;
  onSave?: (note?: DoctorNote) => void;
};

export const AnnotationEdit = (props: AnnotationEditProps) => {
  const {
    appoID,
    patiID,
    currentNote,
    formRef,
    onSave,
    loading: searchLoad,
    ...others
  } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const onCreate = React.useCallback(
    (text: string): Promise<DoctorNote | undefined> => {
      if (!patiID) {
        alert("Falha ao encontrar paciente");
        return Promise.resolve(undefined);
      }
      const note = {
        patiID,
        appoID,
        text,
      } as DoctorNote;
      return dispatch(createOneDoctorNote(note));
    },
    [patiID, appoID, dispatch]
  );

  const onUpdate = React.useCallback(
    (text: string): Promise<DoctorNote | undefined> => {
      if (!currentNote) {
        return Promise.resolve(currentNote);
      }
      return dispatch(changeDoctorNote(currentNote.donoID, text));
    },
    [currentNote, dispatch]
  );

  const onSubmit = React.useCallback(
    (text: string): Promise<DoctorNote | undefined> => {
      if (!currentNote) {
        return onCreate(text);
      }
      return onUpdate(text);
    },
    [currentNote, onCreate, onUpdate]
  );

  const handleSubmit = React.useCallback(
    async (err: any, values: any, form: any) => {
      if (err) {
        return false;
      }
      let save = true;
      setLoading(true);
      await onSubmit(values.text)
        .then((r) => {
          onSave?.(r);
          resetFormAndRun(form, () => ({}));
        })
        .catch((e) => {
          console.warn(e);
          save = false;
        })
        .finally(() => setLoading(false));
      return save;
    },
    [onSubmit, onSave]
  );
  const initialValue = React.useMemo(
    () => ({ text: currentNote?.text }),
    [currentNote]
  );
  return (
    <>
      <div style={{ height: "auto", flex: 0 }}>
        {searchLoad ? <LinearProgress /> : null}
      </div>
      <Form
        formRef={formRef}
        loading={loading}
        initialValue={initialValue}
        onSubmit={handleSubmit}
        {...others}
      />
    </>
  );
};

export const AppointmentNote = (
  props: { appoID: string } & Omit<
    AnnotationEditProps,
    "appoID" | "currentNote" | "loading"
  >
) => {
  const [loading, currentNote] = useGetAppointmentNote(props.appoID);

  return (
    <AnnotationEdit {...props} currentNote={currentNote} loading={loading} />
  );
};

export const PatientNote = (
  props: { patiID: string } & Omit<
    AnnotationEditProps,
    "patiID" | "appoID" | "currentNote" | "loading"
  >
) => {
  const [loading, currentNote] = useGetPatientNote(props.patiID);

  return (
    <AnnotationEdit {...props} currentNote={currentNote} loading={loading} />
  );
};
