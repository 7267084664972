import { format, formatOffset } from "@udok/lib/internal/util";
import {
  ScheduleType,
  AppointmentStatus,
  Schedule,
} from "@udok/lib/api/models";
import {
  DefaultAptyID,
  DefaultPlan,
  FutureInfiniteDate,
} from "@udok/lib/internal/constants";
import moment, { Moment } from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type ScheduleForm = {
  type: string;
  clinID?: string;
  exprID: string;
  userID: string;
  locaID?: string;
  startDate?: string;
  endDate?: string;
  addPeriod?: boolean;
  startAt: string;
  endAt: string;
  typeHour: string;
  appointmentDuration: number;
  weekDay: string[];
  price: number;
  aptyIDList: string[];
  defaultOption: string;
  selfService?: boolean;
  healthPlans?: string[];
  sharedResources?: string[];
  exclusiveListing?: boolean;
  eventDuration: number;
  weekdayTimezoneOffset?: number;
  documentCancelAfterMinutes?: number;
  simultaneousAppointments: number;
  defaultStatus?: string;
  marketplaceOffer?: boolean;
  paymentSplitValue?: string;
  paymentSplitPercent?: string;
  blockUnpaidAppointment?: boolean;
  paymentCancelAfterMinutes?: number;
};

export enum FormDisabledType {
  update = "update",
  disabled = "disabled",
}

export enum TypeHour {
  weekly = "weekly",
  date = "date",
}

export function validateTime(getFieldValue: any) {
  return (val: any, src: any, cb: (r?: string) => void) => {
    const eventDuration = getFieldValue("eventDuration");
    const value = parseInt(src);
    if (value > eventDuration) {
      cb("O valor passado é maior que a duração da agenda");
      return;
    }
    if (value <= 0) {
      cb("O valor passado  deve ser maior que zero");
      return;
    }
    cb();
  };
}

export function validateDate(getFieldValue: any) {
  return (val: any, src: any, cb: (r?: string) => void) => {
    const typeHour = getFieldValue("typeHour");
    if (typeHour === TypeHour.date && !src?.trim?.()) {
      cb("A data é requerida");
      return;
    }
    const date = moment(src, format.DASHUN);
    if (date.isBefore(moment(), "day")) {
      cb("Data inválida");
      return;
    }
    cb();
  };
}

export function validateWeekdays(getFieldValue: any) {
  return (val: any, src: any, cb: (r?: string) => void) => {
    const schedType = getFieldValue("typeHour");
    if (schedType === TypeHour.weekly && (src?.length ?? 0) < 1) {
      cb("Ao menos um dia da semana é obrigatório");
      return;
    }
    cb();
  };
}

export function validateSimultaneousNumber(
  val: any,
  src: any,
  cb: (r?: string) => void
) {
  const value = parseInt(src);
  if (isNaN(value) || value < 1) {
    cb("Campo obrigatório");
    return;
  }
  cb();
}

export function calcDuration(startAt: string, endAt: string) {
  let start = moment(startAt, format.TIME24H);
  let end = moment(endAt, format.TIME24H);
  if (end.isBefore(start)) {
    end.add(1, "day");
  }
  let diff = moment.duration(end.diff(start));
  return diff.asMinutes();
}

export function formatScheduleToForm(schedule: Schedule) {
  const offset = Math.floor((schedule?.weekdayTimezoneOffset ?? -10800) / 3600);
  const startHour = moment
    .utc(schedule?.startAt)
    .utcOffset(offset)
    .format(format.TIME24H);
  const endHour = moment(startHour, format.TIME24H)
    .add(schedule?.eventDuration, "minutes")
    .format(format.TIME24H);
  const endDate = moment(schedule?.endAt).local().format(format.DASHUN);
  const listAppoType = schedule?.appointmentOptions?.map((a) => a.aptyID);
  const defaultAppoType = schedule?.appointmentOptions?.filter?.(
    (a) => a.default === true
  )?.[0];

  return {
    type: schedule?.type,
    clinID: schedule?.clinID,
    exprID: schedule?.procedures?.[0],
    userID: schedule?.userID,
    locaID: schedule?.locaID,
    startDate: moment.utc(schedule?.startAt).local().format(format.DASHUN),
    endDate: endDate !== FutureInfiniteDate ? endDate : undefined,
    addPeriod: endDate !== FutureInfiniteDate,
    startAt: startHour,
    endAt: endHour,
    typeHour:
      schedule?.weekDay && schedule.weekDay.length === 0
        ? TypeHour.date
        : TypeHour.weekly,
    appointmentDuration: schedule?.appointmentDuration,
    weekDay: schedule?.weekDay ?? [],
    price: schedule?.price,
    aptyIDList: listAppoType,
    defaultOption: defaultAppoType?.aptyID,
    selfService: schedule?.selfService,
    healthPlans: schedule?.healthPlans,
    sharedResources: schedule?.sharedResources,
    exclusiveListing: schedule?.exclusiveListing,
    eventDuration: schedule?.eventDuration,
    weekdayTimezoneOffset:
      schedule?.weekdayTimezoneOffset ?? moment().utcOffset() * 60,
    documentCancelAfterMinutes: schedule?.cancelAfterMinutes?.document,
    simultaneousAppointments: schedule?.simultaneousAppointments,
    defaultStatus: schedule?.defaultStatus,
    marketplaceOffer: schedule?.marketplaceOffer,
    paymentSplitPercent: schedule?.paymentSplitPercent,
    paymentSplitValue: schedule?.paymentSplitValue,
    blockUnpaidAppointment: schedule?.blockUnpaidAppointment,
    paymentCancelAfterMinutes: schedule?.cancelAfterMinutes?.payment,
  } as ScheduleForm;
}

export function formatFormToSchedule(values: ScheduleForm) {
  const offset = values?.weekdayTimezoneOffset ?? moment().utcOffset() * 60;
  const formatedOffset = formatOffset(offset);

  let startAt: Moment;
  let weekDay = values?.weekDay ?? [];
  if (values.typeHour === TypeHour.date) {
    weekDay = [];
    startAt = moment(`${values.startDate} ${values.startAt}${formatedOffset}`);
  } else {
    const startDate = moment(values.startDate);
    const now = startDate.isValid()
      ? startDate.format(format.DASHUN)
      : moment().format(format.DASHUN);
    startAt = moment(`${now} ${values.startAt}${formatedOffset}`);
  }
  const aptyIDList = values.aptyIDList ?? [];
  const appointmentOptions = aptyIDList.map((v) => ({
    aptyID: v,
    default: v === values.defaultOption,
  }));
  const endHour = moment(startAt)
    .add(values.eventDuration, "minutes")
    .local()
    .format(format.TIME24H);
  const endDate = values?.endDate
    ? moment(`${values.endDate} ${endHour}`, format.DATEHOUR)
    : undefined;

  const schedule = {
    weekDay: weekDay,
    type: values.type,
    appointmentOptions,
    price: values.price,
    userID: values.userID,
    locaID: values.locaID,
    healthPlans: values.healthPlans,
    selfService: values.selfService,
    eventDuration: values.eventDuration,
    defaultStatus: values?.defaultStatus,
    sharedResources: values?.sharedResources,
    exclusiveListing: values.exclusiveListing,
    startAt: startAt.utc().format(format.RFC3349),
    appointmentDuration: values.appointmentDuration,
    weekdayTimezoneOffset: values?.weekdayTimezoneOffset,
    procedures: values?.exprID ? [values.exprID] : undefined,
    simultaneousAppointments: values?.simultaneousAppointments,
    marketplaceOffer: values?.marketplaceOffer,
    blockUnpaidAppointment: values?.blockUnpaidAppointment,
    paymentSplitPercent: values?.paymentSplitPercent,
    paymentSplitValue: values?.paymentSplitValue,
    endAt:
      endDate && endDate.isValid()
        ? endDate.utc().format(format.RFC3349)
        : undefined,
  } as Schedule;

  const paymentCancelAfterMinutes = values?.paymentCancelAfterMinutes ?? NaN;
  if (!isNaN(paymentCancelAfterMinutes)) {
    schedule.cancelAfterMinutes = {
      ...schedule.cancelAfterMinutes,
      payment: paymentCancelAfterMinutes,
    };
  }

  const documentCancelAfterMinutes = values?.documentCancelAfterMinutes ?? NaN;
  if (!isNaN(documentCancelAfterMinutes)) {
    schedule.cancelAfterMinutes = {
      ...schedule.cancelAfterMinutes,
      document: documentCancelAfterMinutes,
    };
  }
  return schedule;
}

export const DefaultScheduleForm: Partial<ScheduleForm> = {
  price: 0,
  weekDay: [],
  selfService: true,
  sharedResources: [],
  exclusiveListing: false,
  typeHour: TypeHour.weekly,
  type: ScheduleType.virtual,
  healthPlans: [DefaultPlan],
  aptyIDList: [DefaultAptyID],
  simultaneousAppointments: 1,
  defaultOption: DefaultAptyID,
  startDate: moment().format(format.DASHUN),
  defaultStatus: AppointmentStatus.scheduled,
  weekdayTimezoneOffset: moment().utcOffset() * 60,
};
