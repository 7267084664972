import React from "react";
import Icons from "@udok/lib/components/Icon";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

interface FullscreenDialogProps extends DialogProps {
  title?: string;
}

const FullscreenDialog = (props: FullscreenDialogProps) => {
  const { onClose, children, title, ...other } = props;
  const classes = useStyles();

  return (
    <Dialog fullScreen {...other}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => onClose?.(e as any, "backdropClick")}
            aria-label="close"
          >
            <Icons.Close />
          </IconButton>
          {title ? (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          ) : null}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};

export default FullscreenDialog;
