import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "ducks/state";
import { SelectProps } from "@material-ui/core";
import {
  sharedResourceListView,
  loadCachedSharedResource,
  loadScheduleEditorResources,
} from "ducks/sharedResource";
import Selector from "components/Selector";
import { useGetUserMe } from "hooks/user";

export interface SelectSharedResourcesProps {
  loadData?: boolean;
  size?: "small" | "medium";
  margin?: "dense" | "none" | "normal";
  onChange?: (shreID: string | string[] | undefined) => void;
  error?: boolean;
  errorMessage?: string;
  multiple?: boolean;
  value?: string | string[];
  style?: React.CSSProperties;
  fullWidth?: SelectProps["fullWidth"];
  placeholder?: string;
  label?: string;
  clearable?: boolean;
  selectProps?: SelectProps;
  variant?: "outlined";
  disabled?: boolean;
}
const SelectSharedResources = (props: SelectSharedResourcesProps) => {
  const {
    onChange,
    size,
    margin,
    loadData = true,
    style,
    error,
    errorMessage,
    multiple,
    value,
    label,
    fullWidth,
    placeholder,
    clearable,
    selectProps,
    variant,
    disabled,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [loadValues, setLoadValues] = React.useState(false);
  const [, currentUser] = useGetUserMe();
  const dispatch: AppDispatch = useDispatch();
  const { list } = useSelector(sharedResourceListView);

  const [shreIDs, val] = React.useMemo(() => {
    let shreIDs: string[] = [];
    let val: string | string[] | undefined = multiple ? [] : undefined;
    if (value) {
      shreIDs = Array.isArray(value) ? value : [value];
      val = Array.isArray(value)
        ? multiple
          ? value
          : value[0]
        : multiple
        ? [value]
        : value;
    }
    return [shreIDs, val];
  }, [value, multiple]);

  React.useEffect(() => {
    if (shreIDs.length > 0) {
      setLoadValues(true);
      Promise.all(
        shreIDs.map((shreID) => dispatch(loadCachedSharedResource(shreID)))
      )
        .catch(console.warn)
        .finally(() => setLoadValues(false));
    }
  }, [dispatch, shreIDs]);

  React.useEffect(() => {
    if (loadData) {
      setLoading(true);
      dispatch(loadScheduleEditorResources())
        .catch(console.warn)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, loadData]);

  const filteredList = disabled
    ? list
    : list.filter((s) => s.doctID === currentUser?.doctor?.doctID);

  return (
    <Selector
      list={filteredList.map((s) => ({
        value: s.shreID,
        label: `${s.name} - Uso simultâneo: ${s.concurrentUse.toString()}`,
      }))}
      size={size}
      margin={margin}
      loading={loading || loadValues}
      error={error}
      value={val}
      placeholder={placeholder}
      onChange={onChange}
      style={style}
      label={label}
      fullWidth={fullWidth}
      multiple={multiple}
      SelectProps={selectProps}
      clearable={clearable}
      errorMessage={errorMessage}
      variant={variant}
      disabled={disabled}
    />
  );
};

export default SelectSharedResources;
