import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import { Route, Switch } from "react-router";

export const Navigation = Loadable({
  loader: () => import("screens/redirects/vidaasQrcode"),
  loading: Loader,
});

export const Example = Loadable({
  loader: () => import("screens/redirects/webUdokFlowExample"),
  loading: Loader,
});

export default () => {
  return (
    <Switch>
      <Route path="/redirects/vidaas/qrcode" component={Navigation} />
      <Route path="/redirects/webUdok/flow-example" component={Example} />
    </Switch>
  );
};
