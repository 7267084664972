import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import {
  createStyles,
  Grid,
  Hidden,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  Button,
} from "@material-ui/core";
import AppointmentNewEra from "@udok/lib/components/AdCopy/AppointmentScreens";
import { toQueryString, generateWhatsappURL } from "@udok/lib/internal/util";
import { UdokSupportContactNumber } from "@udok/lib/internal/constants";
import { useLocalConfig, SettingsKeys } from "@udok/lib/hooks/localConfig";
import Icons from "@udok/lib/components/Icon";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useOnboardingRedirectOnRendering } from "hooks/auth";

import moment from "moment";
moment.locale("pt-br");

export const PasswordResetChoose = Loadable({
  loader: () => import("containers/Auth/PasswordResetChoose"),
  loading: Loader,
});

export const SignoutContainer = Loadable({
  loader: () => import("containers/Auth/SignoutContainer"),
  loading: Loader,
});

export const PasswordReset = Loadable({
  loader: () => import("containers/Auth/PasswordReset"),
  loading: Loader,
});

export const PasswordResetSucess = Loadable({
  loader: () => import("containers/Auth/PasswordResetSucess"),
  loading: Loader,
});

export const TokenLogin = Loadable({
  loader: () => import("containers/Auth/FormTokenLogin"),
  loading: Loader,
});

export const OnboardingForm = Loadable({
  loader: () => import("containers/Auth/OnboardingForm"),
  loading: Loader,
});

const styles = (theme: Theme) =>
  createStyles({
    container: {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
    grid: {
      height: "100%",
    },
    bg: {
      background: 'url("/static/img/intro-udok.jpg") center top no-repeat',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    highlighBorder: {
      width: 600,
      borderRadius: "12px",
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    text: {
      margin: theme.spacing(3),
      color: theme.palette.neutral.lightest,
    },
    formSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      maxHeight: "100vh",
      [theme.breakpoints.down("md")]: {
        background: 'url("/static/img/intro-udok.jpg") center top no-repeat',
      },
      [theme.breakpoints.down("sm")]: {
        maxHeight: "auto",
      },
      [theme.breakpoints.up("md")]: {
        overflow: "auto",
      },
    },
    formInner: {
      margin: theme.spacing(3),
      maxWidth: 640,
    },
    formWrapper: {
      [theme.breakpoints.up("md")]: {
        maxWidth: 500,
      },
      [theme.breakpoints.down("md")]: {
        background: theme.palette.neutral.lightest,
        borderRadius: theme.spacing(3),
        padding: theme.spacing(3),
      },
    },
    formTitle: {
      color: theme.palette.primary.darkest,
      textAlign: "center",
      marginTop: theme.spacing(3),
    },
    formText: {
      color: theme.palette.neutral.darkest,
      textAlign: "center",
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: theme.spacing(2),
      color: theme.palette.common.white,
      "& > *": {
        maxWidth: 400,
        padding: theme.spacing(1),
        backgroundColor: "#052193",
        marginBottom: theme.spacing(2),
        "&:hover": {
          backgroundColor: "#051d7c",
        },
      },
      "& > *:last-child": {
        marginBottom: 0,
      },
    },
  });

export interface OnboardingContainerProps extends WithStyles<typeof styles> {}
const OnboardingContainer = ({ classes }: OnboardingContainerProps) => {
  const history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const offer = query.get("offer") ?? undefined;
  useOnboardingRedirectOnRendering();
  const [, setLocalConfig] = useLocalConfig(SettingsKeys.RouterQueryString);
  const formRef = React.useRef<HTMLDivElement>(null);

  const goToForm = React.useCallback(() => {
    const form = formRef?.current;
    if (!!form) {
      form?.scrollIntoView?.({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [formRef]);

  const goToPlans = React.useCallback(() => {
    window.open(
      process.env.REACT_APP_MARKETING_LANDINGPAGE,
      "_blank",
      "noopener noreferrer"
    );
  }, []);

  const talkToAttendant = React.useCallback(() => {
    window.open(
      generateWhatsappURL(
        UdokSupportContactNumber,
        "Olá! Tenho interesse em saber mais sobre a UDOK"
      ),
      "_blank",
      "noopener noreferrer"
    );
  }, []);

  const onRedirect = React.useCallback(() => {
    setLocalConfig({ offer });
  }, [offer, setLocalConfig]);

  return (
    <div className={classes.container}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} lg={6} className={classes.bg}>
          <div className={classes.highlighBorder}>
            <Typography
              variant="h3"
              style={{ textAlign: "center" }}
              className={clsx(classes.text, classes.title)}
            >
              Seja bem-vindo a UDOK
              <br />
              Agendamento Digital Inteligente
            </Typography>
            <Typography variant="body1" className={classes.text}>
              <p>
                A tecnologia UDOK.Digital de captação e marcação online
                inteligente vai proporcionar um aumento da produtividade da sua
                Clínica ou Instituição de Saúde, com qualificação da clientela e
                engajamento de pacientes.
              </p>
              <p>
                Com um perfil profissional no UDOK.Digital, você terá uma página
                individualizada de agendamento com filtros inteligentes que
                facilitam a marcação online de forma automática e intuitiva,
                facilitando a jornada dos seus pacientes.
              </p>
            </Typography>
            <Hidden mdDown>
              <AppointmentNewEra />
            </Hidden>
            <div className={classes.buttonContainer}>
              <Hidden smUp>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(255 255 255 / 88%)",
                  }}
                  onClick={goToForm}
                >
                  Comece gratis
                </Button>
              </Hidden>
              <Button
                fullWidth
                variant="outlined"
                color="inherit"
                onClick={talkToAttendant}
                startIcon={<Icons.WhatsApp />}
              >
                Fale com um dos nossos especialistas
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="inherit"
                onClick={goToPlans}
              >
                Conheça nossas soluções e planos
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.formSection}>
          <div ref={formRef} className={classes.formWrapper}>
            <Typography variant="h4" className={classes.formTitle}>
              Transforme a realidade e o faturamento do seu consultório!
            </Typography>
            <Typography variant="body1" className={classes.formText}>
              Crie sua conta UDOK Digital gratuitamente e aproveite para
              conhecer e testar nossas soluções, otimizando sua rotina médica,
              qualificando sua agenda e aprimorando seus atendimentos com o
              auxílio da tecnologia UDOK.
            </Typography>
            <OnboardingForm
              className={classes.formInner}
              onSuccess={() =>
                history.push(`/admin/explore${toQueryString({ offer })}`)
              }
              onRedirectScreen={onRedirect}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(OnboardingContainer);
