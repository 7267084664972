import React from "react";
import Editor, { EditorRef } from "./Editor";
import SignatureOptions from "./SignatureOptions";
import { Options } from "./Types";
import { Button, CircularProgress } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export const DefaultOptions = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) =>
  ({
    background: {
      image: null,
      visible: true,
      shape: {
        x: width / 2,
        y: height / 2,
        width: height,
        height: height,
      },
    },
    lateral: {
      image: null,
      visible: true,
      alignLeft: true,
    },
    signature: {
      visible: true,
      lines: [],
      color: "#000",
    },
    text: {
      color: "#000",
      visible: true,
      value: "\n",
    },
  } as Options);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ButtonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      margin: theme.spacing(2, 0),
    },
    buttonSave: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

export interface BuilderProps {
  width: number;
  height: number;
  defaultOptions: Options;
  onChange: (img: string) => void;
}

const Builder = ({ onChange, defaultOptions, ...others }: BuilderProps) => {
  const [options, setOptions] = React.useState<Options>(defaultOptions);
  const [loading, setL] = React.useState(false);
  const ref = React.useRef<EditorRef | null>(null);
  const classes = useStyles();

  const handleSave = () => {
    if (ref.current) {
      setL(true);
      ref.current.toImage({
        pixelRatio: 1,
        mimeType: "image/png",
        callback: (img) => {
          setL(false);
          if (img.src) {
            onChange(img.src);
          }
        },
      });
    }
  };

  return (
    <>
      <Editor
        ref={ref}
        options={options}
        onChange={(o) => setOptions(o)}
        {...others}
      />
      <SignatureOptions
        width={others.width}
        height={others.height}
        options={options}
        onChange={setOptions}
      />
      <div className={classes.ButtonContainer}>
        <Button
          variant="outlined"
          color="primary"
          endIcon={loading ? <CircularProgress size={14} /> : null}
          onClick={handleSave}
          className={classes.buttonSave}
        >
          Salvar
        </Button>
      </div>
    </>
  );
};

export default Builder;
