import React from "react";
import { SelectProps, TextField, TextFieldProps } from "@material-ui/core";
import { validateCPF, validateCNPJ } from "@udok/lib/internal/util";

export enum BankDocumentType {
  cpf = "cpf",
  cnpj = "cnpj",
}

export function getDocumentType(number: string) {
  if (validateCPF(number)) {
    return BankDocumentType.cpf;
  }
  if (validateCNPJ(number)) {
    return BankDocumentType.cnpj;
  }
  return undefined;
}

export type BankDocumentTypeSelectProps = {
  value?: Pick<SelectProps, "value">;
  onChange?: (v: string) => void;
} & Omit<
  TextFieldProps,
  "onChange" | "value" | "select" | "children" | "SelectProps"
>;

const BankDocumentTypeSelect = React.forwardRef(
  (props: any, ref: React.Ref<any>) => {
    const { value, onChange, ...others } = props;

    return (
      <TextField
        select
        ref={ref}
        margin="normal"
        value={value}
        onChange={(e) =>
          onChange?.((e.target.value as string) ?? BankDocumentType.cpf)
        }
        variant="outlined"
        SelectProps={{
          displayEmpty: true,
          native: true,
        }}
        style={{ minWidth: "9.5rem" }}
        {...others}
      >
        <option value={BankDocumentType.cpf}>CPF</option>
        <option value={BankDocumentType.cnpj}>CNPJ</option>
      </TextField>
    );
  }
);

export default BankDocumentTypeSelect;
