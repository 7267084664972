import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  AppointmentInstruction,
  AppointmentType,
  AppointmentInstructionFilter,
  AppointmentInstructionForm,
} from "@udok/lib/api/models";
import {
  createAppointmentInstruction,
  getAppointmentInstruction,
  listAppointmentInstructions,
  deleteAppointmentInstruction,
  updateAppointmentInstruction,
} from "@udok/lib/api/appointmentInstruction";
import { getToken, UNAUTHORIZED } from "./auth";
import { getListAppointmentType } from "./appointmentType";
import { DefaultAptyID } from "@udok/lib/internal/constants";

export type InitialState = {
  appointmentInstructionByID: { [apinID: number]: AppointmentInstruction };
};

// Reducers
const initialState: InitialState = {
  appointmentInstructionByID: {},
};

class AppointmentInstructions extends Hen<InitialState> {
  appointmentInstructionLoaded(m: AppointmentInstruction) {
    this.state.appointmentInstructionByID[m.apinID] = m;
  }
  appointmentInstructionsLoaded(ms: AppointmentInstruction[]) {
    ms.forEach((m) => {
      this.state.appointmentInstructionByID[m.apinID] = m;
    });
  }
  appointmentInstructionRemoved(apinID: number) {
    delete this.state.appointmentInstructionByID[apinID];
  }
}

export const [Reducer, actions] = hen(
  new AppointmentInstructions(initialState),
  {
    [UNAUTHORIZED]: () => {
      return initialState;
    },
  }
);

// Selectors
const mainSelector = (state: RootState) => state.appointmentInstruction;

export const getListAppointmentInstruction = createSelector(
  [mainSelector],
  (state) => {
    return {
      list: Object.keys(state.appointmentInstructionByID).map(
        (apinID) => state.appointmentInstructionByID[Number(apinID)]
      ),
    };
  }
);

export const getAvailableAptyID = createSelector(
  [getListAppointmentInstruction, getListAppointmentType],
  (instructions, aptyIDs) => {
    const defaultApty: AppointmentType = {
      aptyID: DefaultAptyID,
      type: "default",
      name: "Padrão do sistema",
      description: "Consulta Padrão",
      validationSchema: {
        type: "object",
        required: [],
        properties: {},
      },
      medicalHistoryTemplates: [],
      collectDocumentVisibilitys: [],
      documentTemplateVisibilitys: [],
      visibility: [],
    };
    return {
      list: [...aptyIDs.list, defaultApty].filter(
        (aptyID) =>
          !instructions.list.find(
            (instruction) => instruction.aptyID === aptyID.aptyID
          )
      ),
    };
  }
);

export const getOneAppointmentInstruction = (props: { apinID: number }) =>
  createSelector(mainSelector, (state) => {
    return {
      appointmentInstruction: state.appointmentInstructionByID[props.apinID],
    };
  });

// Actions
export function createNewAppointmentInstruction(
  form: AppointmentInstructionForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointmentInstruction(apiToken, form)
      .then((r) => {
        dispatch(actions.appointmentInstructionLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentInstructions(
  filter?: AppointmentInstructionFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return listAppointmentInstructions(apiToken, filter)
      .then((r) => {
        dispatch(actions.appointmentInstructionsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneAppointmentInstruction(
  apinID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getAppointmentInstruction(apiToken, apinID)
      .then((r) => {
        dispatch(actions.appointmentInstructionLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedAppointmentInstruction(
  apinID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const postExist = Boolean(
      state.appointmentInstruction.appointmentInstructionByID[apinID]
    );
    if (postExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneAppointmentInstruction(apinID));
  };
}

export function removeAppointmentInstruction(
  apinID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteAppointmentInstruction(apiToken, apinID)
      .then((r) => {
        dispatch(actions.appointmentInstructionRemoved(r.apinID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeAppointmentInstruction(
  form: AppointmentInstructionForm & { apinID: number }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateAppointmentInstruction(apiToken, form)
      .then((r) => {
        dispatch(actions.appointmentInstructionLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
