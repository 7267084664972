import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MarkdownViewer from "@udok/lib/components/Markdown/MarkdownViewer";
import clsx from "clsx";

const dataImageDoneRounded = (color: string) => {
  return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%0Afocusable='false'%0AviewBox='0 0 24 24'%0Aaria-hidden='true'%0Awidth='5.4mm'%0Aheight='4.9mm'%0Afill='${color.replace(
    "#",
    "%23"
  )}'%0A%3E%3Cpath vector-effect='none' fill-rule='nonzero' d='M9,16.2 L5.5,12.7 C5.31515,12.5129 5.06305,12.4075 4.8,12.4075 C4.53695,12.4075 4.28485,12.5129 4.1,12.7 C3.71,13.09 3.71,13.71 4.1,14.1 L8.29,18.29 C8.68,18.68 9.31,18.68 9.7,18.29 L20.3,7.7 C20.69,7.31 20.69,6.69 20.3,6.3 C20.1151,6.11285 19.863,6.00752 19.6,6.00752 C19.3369,6.00752 19.0849,6.11285 18.9,6.3 L9,16.2'/%3E%3C/svg%3E`;
};

const dataImageCloseRounded = (color: string) => {
  return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%0Aclass='MuiSvgIcon-root jss179'%0Afocusable='false'%0AviewBox='0 0 24 24'%0Aaria-hidden='true'%0Awidth='5.4mm'%0Aheight='4.9mm'%0Afill='${color.replace(
    "#",
    "%23"
  )}'%0A%3E%3Cpath d='M18.3 5.71a.9959.9959 0 00-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 00-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z'%3E%3C/path%3E%3C/svg%3E`;
};

const useStyles = makeStyles((theme: Theme) => {
  const colorWhite = theme.palette.common.white;
  const colorBlack = theme.palette.common.black;
  const colorNeutralLight = theme.palette.neutral.light;
  const colorSuccess = theme.palette.success.main;
  return createStyles({
    root: {
      color: colorBlack,
      pointerEvents: "none",
      "& .tui-editor-contents .task-list-item.checked::before": {
        backgroundImage: `url("${dataImageDoneRounded(colorSuccess)}")`,
      },
      "& .tui-editor-contents .task-list-item::before": {
        pointerEvents: "none",
        backgroundImage: `url("${dataImageCloseRounded(colorNeutralLight)}")`,
      },
      "& .task-list-item": {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
      },
      "& .tui-editor-contents p": {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        whiteSpace: "break-spaces",
      },
    },
    recommended: {
      color: colorWhite,
      "& .tui-editor-contents .task-list-item.checked::before": {
        backgroundImage: `url("${dataImageDoneRounded(colorWhite)}")`,
      },
      "& .task-list-item, & .tui-editor-contents p": {
        color: colorWhite,
      },
    },
  });
});

type PlanDescription = {
  recommended?: boolean;
  description: string;
};

const PlanDescriptionView = (props: PlanDescription) => {
  const { recommended, description } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.recommended]: recommended,
      })}
    >
      <MarkdownViewer markdown={description} />
    </div>
  );
};

export default PlanDescriptionView;
