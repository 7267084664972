import React, { CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "ducks/state";
import { loadMarketplaceRecipients, recipientsView } from "ducks/marketplace";
import { LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

type WarnNoRecipientProps = {
  style?: CSSProperties;
  onChange?: (value: boolean) => void;
};

const WarnNoRecipient = React.forwardRef(
  (props: WarnNoRecipientProps, ref: React.Ref<any>) => {
    const { style, onChange } = props;
    const [loading, setLoading] = React.useState(false);
    const [fetched, setFetched] = React.useState(false);
    const dispatch: AppDispatch = useDispatch();
    const { list } = useSelector(recipientsView);

    React.useEffect(() => {
      setLoading(true);
      dispatch(loadMarketplaceRecipients())
        .then(() => setFetched(true))
        .catch(console.warn)
        .finally(() => {
          setLoading(false);
        });
    }, [dispatch]);
    const noRecipientRegistered = fetched && list.length === 0;

    React.useEffect(() => {
      onChange?.(noRecipientRegistered);
    }, [noRecipientRegistered, onChange]);

    return (
      <div ref={ref} style={style}>
        {loading ? <LinearProgress color="primary" /> : null}
        {noRecipientRegistered ? (
          <Alert severity="warning">
            <span>
              Você ainda não possui uma conta bancária cadastrada para
              recebimento.
            </span>{" "}
            <Link to="/admin/finances/bank-accounts/create">Clique aqui</Link>{" "}
            <span>para iniciar o cadastro</span>
          </Alert>
        ) : null}
      </div>
    );
  }
);

export default WarnNoRecipient;
