import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { createOneCreditCard } from "ducks/billing";
import { createOneDoctorPreference } from "ducks/doctorPreferences";
import { CreditCard, CreditCardForm, Preferences } from "@udok/lib/api/models";
import { Alert } from "@material-ui/lab";
import Form, { CardInitialValues } from "./Form";
// @ts-ignore
import { formShape } from "rc-form";

export type CreateCreditCardProps = {
  initialValues?: CardInitialValues;
  onCreated?: (cc: CreditCard) => void;
};

const CreateCreditCard = (props: CreateCreditCardProps) => {
  const { initialValues, onCreated } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const dispatch: AppDispatch = useDispatch();

  const createPref = React.useCallback(
    (crcaID: string, isDefault?: boolean) => {
      if (isDefault) {
        const pref = {
          prefID: Preferences.financesDefaultCreditCard,
          options: { crcaID },
        };
        return dispatch(createOneDoctorPreference(pref));
      }
    },
    [dispatch]
  );

  const handleCreate = React.useCallback(
    (
      err: any,
      values: CreditCardForm & {
        isDefault?: boolean;
      },
      form: formShape
    ) => {
      if (!!err) {
        return;
      }
      setError(undefined);
      setLoading(true);
      dispatch(createOneCreditCard(values))
        .then((res) => {
          createPref(res.crcaID, values?.isDefault);
          onCreated?.(res);
          form.resetFields();
        })
        .catch((e) => {
          window.scrollTo(0, 0);
          setError(e?.message ?? "Erro ao cadastrar cartão");
        })
        .finally(() => setLoading(false));
    },
    [dispatch, createPref, onCreated]
  );

  return (
    <>
      {!loading && error ? (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      ) : null}
      <Form
        onSubmit={handleCreate}
        initialValues={initialValues}
        loading={loading}
      />
    </>
  );
};

export default CreateCreditCard;
