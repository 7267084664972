import React from "react";
import { Typography, CardMedia, Button, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import Illustrations from "@udok/lib/components/Illustrations";
import Icons from "@udok/lib/components/Icon";
import { getImageURL } from "@udok/lib/internal/util";
import ModalSignature from "containers/User/ModalSignature";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signatureContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    signatureButton: {
      display: "flex",
      position: "relative",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    signatureImageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 66,
      height: 66,
      backgroundColor: theme.palette.neutral.ultralight,
      fill: theme.palette.secondary.lightest,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
    },
    signatureLabel: {
      marginLeft: theme.spacing(2),
      color: theme.palette.neutral.dark,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "60%",
    },
    clearButton: {
      position: "absolute",
      right: 0,
      top: 0,
      color: theme.palette.neutral.main,
      "&:hover": {
        color: theme.palette.error.main,
      },
    },
  })
);

const [rendererDialog, promiseDialog] = createModal(ModalSignature);

const EditSignature = ({
  onChange,
  value,
  clearSignature,
}: {
  onChange?: (value?: File | string) => void;
  value?: File | string;
  clearSignature?: boolean;
}) => {
  const classes = useStyles();
  const baseURL = process.env.REACT_APP_BASE_PATH + "/files/";
  const imgSrc = getImageURL(baseURL, value);
  const onOpen = async () => {
    await promiseDialog({ onChange });
  };

  const handleClear = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onChange?.(undefined);
    },
    [onChange]
  );

  return (
    <div className={classes.signatureContainer}>
      <Button className={classes.signatureButton} onClick={onOpen}>
        <div className={classes.signatureImageContainer}>
          {imgSrc ? (
            <CardMedia
              component="img"
              image={imgSrc}
              style={{
                width: "90%",
                height: "90%",
              }}
            />
          ) : (
            <Illustrations.ImageExample width="50%" height="50%" />
          )}
        </div>
        <Typography variant="body2" className={classes.signatureLabel}>
          <b>Construir nova assinatura</b>
        </Typography>
        {clearSignature ? (
          <IconButton className={classes.clearButton} onClick={handleClear}>
            <Icons.DeleteOutline />
          </IconButton>
        ) : null}
      </Button>
      {rendererDialog}
    </div>
  );
};

export default EditSignature;
