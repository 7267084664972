import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = (row?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        ...(row
          ? { display: "flex", flexDirection: "row", flexWrap: "wrap" }
          : {}),
        "&>*:not(:last-child)": {
          marginRight: theme.spacing(1),
        },
      },
    })
  );

const FormRow = ({
  row,
  children,
}: {
  row?: boolean;
  children: React.ReactNodeArray | React.ReactNode;
}) => {
  const classes = useStyles(row)();
  return <div className={classes.root}>{children}</div>;
};

export default FormRow;
