import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden",
      height: 280,
      width: 600,
    },
    logoContainer: {
      display: "flex",
      backgroundColor: theme.palette.neutral.lightest,
      height: "95%",
      border: `4px solid ${theme.palette.primary.main}`,
      borderRadius: 15,
      padding: theme.spacing(0.5),
      visibility: "visible",
      animationDuration: "0.6s",
      animationDelay: "0.4s",
      animationName: "fadeInUp",
    },
  })
);

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoContainer} data-wow-duration="0.6s">
        <img width="auto" height="100%" src="/static/img/udok-pro.png" alt="" />
      </div>
    </div>
  );
};
