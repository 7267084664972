import AlertDialog, {
  ResourceAlertDialogProps,
} from "@udok/lib/components/LimitedResourceAlert/Dialog";
import AlertSnackbar, {
  ResourceAlertSnackbarProps,
} from "@udok/lib/components/LimitedResourceAlert/Snackbar";
import AlertPopover, {
  ResourceAlertPopoverProps,
} from "@udok/lib/components/LimitedResourceAlert/Popover";
import AlertInline, {
  ResourceAlertInlineProps,
} from "@udok/lib/components/LimitedResourceAlert/Inline";

export type AlertDialogProps = ResourceAlertDialogProps;
export type AlertSnackbarProps = ResourceAlertSnackbarProps;
export type AlertPopoverProps = ResourceAlertPopoverProps;
export type AlertInlineProps = ResourceAlertInlineProps;
export default {
  Dialog: AlertDialog,
  Snackbar: AlertSnackbar,
  Popover: AlertPopover,
  Inline: AlertInline,
};
