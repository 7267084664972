import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  CircularProgress,
  Button,
  Avatar,
  TextField,
  TextFieldProps,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import Dialog from "@udok/lib/components/Dialog/ImageDialog";
import Illustrations from "@udok/lib/components/Illustrations";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  rootError: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
    borderRadius: theme.spacing(1),
  },
  fileInputButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  inputImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.neutral.ultralight,
    fill: theme.palette.secondary.lightest,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  fileInputLabel: {
    marginLeft: theme.spacing(2),
    color: theme.palette.neutral.dark,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "60%",
  },
  fileInputLabelError: {
    color: theme.palette.error.main,
  },
  large: (props: { type?: any }) => ({
    width: props?.type === "banner" ? theme.spacing(8) : theme.spacing(7),
    height: theme.spacing(7),
  }),
}));

export type ImagePickerProps = {
  htmlFor: string;
  value?: string;
  onChange?: (f?: File | File[]) => void;
  loading?: boolean;
  baseUrl?: string;
  type: string;
  error?: string;
  dimensionWidth?: number;
  dimensionHeight?: number;
  otherDimensions?: { width: number; height: number }[];
  classes?: {
    root?: string;
    large?: string;
    fileInputButton?: string;
  };
} & Omit<
  TextFieldProps,
  | "id"
  | "name"
  | "style"
  | "type"
  | "inputProps"
  | "onChange"
  | "variant"
  | "classes"
  | "error"
>;

const extensions = ["jpg", "jpeg", "png", "gif"];

export const ImagePicker = React.forwardRef(
  (props: ImagePickerProps, ref: React.Ref<any>) => {
    const {
      htmlFor,
      loading,
      baseUrl,
      value,
      onChange,
      type,
      dimensionWidth,
      dimensionHeight,
      error,
      otherDimensions,
      ...others
    } = props;
    const [image, setImage] = React.useState("");
    const [nameImage, setNameImage] = React.useState("");
    const [resizeOpen, setResizeOpen] = React.useState(false);
    const [alertVisible, setAlertVisible] = React.useState(false);
    const classes = styles(props);

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
      window.URL = window.URL || window.webkitURL;
      let url = e?.target?.files?.[0]
        ? window.URL.createObjectURL(e.target.files[0])
        : undefined;
      if (!url) {
        return;
      }
      let name = e.target.value;
      if (!extensions.find((ext) => name?.split(".")?.pop?.() === ext)) {
        setAlertVisible(true);
        setNameImage("");
        return;
      }
      setNameImage(name);
      setImage(url);
      setAlertVisible(false);
      setResizeOpen(true);
    };

    return (
      <>
        <div
          className={clsx(classes.root, others?.classes?.root, {
            [classes.rootError]: Boolean(error),
          })}
        >
          <label htmlFor={htmlFor} style={{ width: "100%", height: "100%" }}>
            <TextField
              id={htmlFor}
              name={htmlFor}
              style={{ display: "none" }}
              type="file"
              inputProps={{
                accept: ".jpg,.jpeg,.png,.gif",
                style: {
                  height: "auto",
                },
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeImage(e);
              }}
              variant="standard"
              {...others}
            />
            <Button
              ref={ref}
              className={clsx(
                classes.fileInputButton,
                others?.className,
                others?.classes?.fileInputButton
              )}
              component="span"
            >
              {value ? (
                <Avatar
                  alt="logo"
                  src={(baseUrl ?? "") + value ?? ""}
                  className={clsx(classes.large, others?.classes?.large)}
                  variant="square"
                />
              ) : (
                <div
                  className={clsx(
                    classes.inputImageContainer,
                    classes.large,
                    others?.classes?.large
                  )}
                >
                  <Illustrations.ImageExample width="50%" height="50%" />
                </div>
              )}
              <Typography
                variant="body2"
                className={clsx(classes.fileInputLabel, {
                  [classes.fileInputLabelError]: Boolean(error),
                })}
              >
                <b>{nameImage || (others?.label ?? "Escolher imagem...")}</b>
                {error ? (
                  <FormHelperText error={true}>{error}</FormHelperText>
                ) : null}
              </Typography>
              {loading && (
                <div style={{ marginLeft: 5, marginBottom: 5 }}>
                  <CircularProgress size={12} />
                </div>
              )}
            </Button>
          </label>

          {resizeOpen && (
            <Dialog
              image={image}
              nameImage={nameImage}
              type={type}
              onConfirm={(img: File | File[]) => {
                onChange?.(img);
                setResizeOpen(false);
              }}
              close={() => {
                setNameImage("");
                setResizeOpen(false);
              }}
              height={dimensionHeight}
              width={dimensionWidth}
              otherDimensions={otherDimensions}
            />
          )}
        </div>
        {alertVisible && (
          <Alert onClose={() => setAlertVisible(false)} severity="warning">
            Arquivo inválido. Tente uma imagem tipo .jpg, .jpeg, .png ou .gif.
          </Alert>
        )}
      </>
    );
  }
);

export default ImagePicker;
