import React from "react";
import {
  Popover,
  PopoverOrigin,
  Typography,
  Button,
  Card,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import CircularTimer from "@udok/lib/components/CircularTimer";
import { LimitedResourceAlertTexts } from "@udok/lib/internal/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverCard: {
      maxWidth: 350,
    },
    container: {
      padding: theme.spacing(1),
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      "& .card-title": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginRight: theme.spacing(1),
      },
    },
    descriptionContainer: {
      display: "flex",
      padding: theme.spacing(1, 0, 2),
      whiteSpace: "break-spaces",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
enum PopoverVariant {
  timer = "timer",
  action = "action",
}

export type ResourceAlertPopoverProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  open: boolean;
  anchorEl: HTMLElement | null;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  countSeconds?: number;
  actions?: React.ReactNode;
  variant?: keyof typeof PopoverVariant;
  close: (viewOffers: boolean) => void;
};

const ResourceAlertPopover = (props: ResourceAlertPopoverProps) => {
  const {
    open,
    anchorEl,
    close,
    variant = PopoverVariant.timer,
    actions,
    countSeconds = 3,
    title = LimitedResourceAlertTexts.title,
    description = LimitedResourceAlertTexts.description,
  } = props;
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    close(false);
  }, [close]);

  const handleViewOffers = React.useCallback(() => {
    close(true);
  }, [close]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
        ...props?.anchorOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
        ...props?.transformOrigin,
      }}
    >
      <Card className={classes.popoverCard}>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Typography color="inherit" className="card-title">
              {title}
            </Typography>
            {variant === PopoverVariant.timer ? (
              <CircularTimer
                countSeconds={countSeconds}
                onEndCount={handleClose}
                size={25}
              />
            ) : null}
          </div>
          <div className={classes.descriptionContainer}>{description}</div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleViewOffers}
            >
              {LimitedResourceAlertTexts.buttonLabel}
            </Button>
            {variant === PopoverVariant.action ? actions : null}
          </div>
        </div>
      </Card>
    </Popover>
  );
};

export default ResourceAlertPopover;
