import React from "react";
// @ts-ignore
import { createForm, formShape } from "rc-form";
import {
  IconButton,
  FormControl,
  FormHelperText,
  Button,
  Typography,
  Toolbar,
  TypographyProps,
  CircularProgress,
} from "@material-ui/core";
import MarkdownEditor, {
  EditorChildID,
} from "@udok/lib/components/Markdown/MarkdownEditor";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  NavigationWhenDirtyContext,
  DirtyScreenKeys,
} from "@udok/lib/components/NavigationConfirmContext";
import { resetFormAndRun } from "@udok/lib/internal/util";
import Icons from "@udok/lib/components/Icon";
import { useGenerateElementPDF } from "hooks/GenerateElementPDF";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

let timeout: any = null;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    noteContainer: {
      border: `1px solid ${theme.palette.neutral.light}`,
      borderRadius: 8,
      height: "100%",
      "&>span": {
        minHeight: 400,
      },
      margin: 0,
    },
    saveContainer: {
      display: "flex",
      flexDirection: "row-reverse",
      marginTop: theme.spacing(1),
    },
    saveButton: {},
    helperText: {
      margin: theme.spacing(1),
    },
    titleContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      marginLeft: theme.spacing(1),
    },
    toolbar: {
      padding: theme.spacing(0, 1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

export type AnnotationFormRef = { cleanForm: () => void };
export interface AnnotationFormProps {
  form?: formShape;
  initialValue?: { text?: string };
  loading?: boolean;
  activeAutoSave?: boolean;
  titleProps?: {
    toolbarClassName?: string;
    containerClassName?: string;
  } & TypographyProps;
  onSubmit: (err: any, values: any, form: any) => boolean | undefined;
}

export const AnnotationForm = React.forwardRef(
  (props: AnnotationFormProps, ref: React.Ref<AnnotationFormRef>) => {
    const {
      form,
      initialValue,
      loading,
      activeAutoSave = true,
      titleProps,
      onSubmit,
    } = props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;
    const [save, setSave] = React.useState<Boolean>();
    const classes = useStyles();
    const container = React.useRef<HTMLDivElement>(null);

    const clearAutoSave = React.useCallback(() => {
      if (!timeout) {
        return;
      }
      clearTimeout(timeout);
    }, []);

    React.useImperativeHandle(ref, () => ({
      cleanForm: () => {
        clearAutoSave();
        setSave(undefined);
        resetFormAndRun(form, () => ({}));
      },
    }));

    React.useEffect(() => {
      return () => {
        clearAutoSave();
      };
    }, [clearAutoSave]);

    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      form?.validateFields?.((err: any, values: any) => {
        const isSave = onSubmit(err, values, form);
        setSave(isSave);
      });
      clearAutoSave();
    };

    const autoSave = () => {
      if (activeAutoSave) {
        clearAutoSave();
        timeout = setTimeout(() => handleSubmit(), 30000);
      }
    };

    return (
      <form className={classes.root} onSubmit={handleSubmit}>
        <Toolbar
          className={clsx(classes.toolbar, titleProps?.toolbarClassName)}
          variant="dense"
        >
          <div
            className={clsx(
              classes.titleContainer,
              titleProps?.containerClassName
            )}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.title}
              {...titleProps}
            >
              <b>Anotações</b>
            </Typography>
            <ExportAnnotation element={container.current} />
          </div>
          {save !== undefined ? (
            <Typography variant="subtitle2" color="textSecondary">
              <u>
                {loading ? (
                  "Salvando alterações..."
                ) : save ? (
                  "As alterações foram salvas"
                ) : (
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    className={classes.saveButton}
                  >
                    Salvar anotação
                  </Button>
                )}
              </u>
            </Typography>
          ) : null}
        </Toolbar>
        <FormControl
          className={classes.noteContainer}
          margin="normal"
          fullWidth
          error={getFieldError("text")}
        >
          {getFieldDecorator("text", {
            rules: [
              {
                required: true,
                type: "string",
                message: "Não é possivel salvar anotações em branco!",
              },
              (a: any, value: string, cb: (e?: string) => void) => {
                if (value && !value.trim()) {
                  cb("Não é possivel salvar anotações em branco!");
                  return;
                }
                cb();
              },
            ],
            onChange: (value: string) => {
              if (getFieldValue("text") !== value) {
                autoSave();
                setSave(false);
              }
            },
            initialValue: initialValue?.text,
          })(
            <MarkdownEditor
              ref={container}
              placeholder="Adicione suas anotações do agendamento"
            />
          )}
          <FormHelperText
            error={getFieldError("text")}
            className={getFieldError("text") ? classes.helperText : undefined}
          >
            {getFieldError("text")}
          </FormHelperText>
        </FormControl>
        <ConfirmNavigation form={form} onExit={clearAutoSave} />
      </form>
    );
  }
);

const ConfirmNavigation = ({
  form,
  onExit,
}: {
  form: any;
  onExit: () => void;
}) => {
  const { setDirty, removeDirty } = React.useContext(
    NavigationWhenDirtyContext
  );
  const isDirty = React.useMemo(
    () =>
      Object.keys(form.getFieldsValue()).filter((x) => form.isFieldTouched(x))
        .length > 0,
    [form]
  );

  React.useEffect(() => {
    if (isDirty) {
      setDirty({
        key: DirtyScreenKeys.doctorNote,
        message: "Você não salvou as alterações. Tem certeza que quer sair?",
        onExit,
      });
      return;
    }
    removeDirty(DirtyScreenKeys.doctorNote);
  }, [isDirty, setDirty, removeDirty, onExit]);

  return null;
};

export default createForm()(
  ({
    formRef,
    ...others
  }: { formRef?: React.Ref<AnnotationFormRef> } & AnnotationFormProps) => (
    <AnnotationForm ref={formRef} {...others} />
  )
);

const ExportAnnotation = ({ element }: { element: HTMLElement | null }) => {
  const [loading, setLoading] = React.useState(false);
  const [generatePDF] = useGenerateElementPDF();

  const onGeneratingPDF = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      const element = document.getElementById(EditorChildID);
      if (element) {
        const originalWidth = element.style.width;
        element.style.width = "900px";
        setLoading(true);
        setTimeout(() => {
          generatePDF(
            element,
            `anotação-${moment().format("DDMMYYYYHHmmss")}.pdf`
          ).finally(() => {
            element.style.width = originalWidth;
            setLoading(false);
          });
        }, 25);
      }
    },
    [generatePDF]
  );

  return (
    <>
      <IconButton
        title="Gerar PDF"
        disabled={loading}
        className="no-print"
        onClick={onGeneratingPDF}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Icons.PictureAsPdfRounded />
        )}
      </IconButton>
    </>
  );
};
