import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import {
  PasswordReset,
  PasswordResetChoose,
  SignoutContainer,
  TokenLogin,
  PasswordResetSucess,
} from "screens/onboarding";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@udok/lib/themes/paperbase";
import Admin from "screens/admin";
import Onboarding from "screens/onboarding";
import Redirects from "screens/redirects";
import Notification from "containers/Notification";

const GeneralNotifications = Notification("general");

const InitialMessenger = () => {
  React.useEffect(() => {
    window.parent.postMessage(
      {
        type: "udok-loaded",
        message: "OK!",
      },
      "*"
    );
  }, []);
  return null;
};

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/">
          <InitialMessenger />
          <Redirect to="/admin" />
        </Route>
        <Route exact path="/password_reset" component={PasswordReset} />
        <Route
          exact
          path="/password_reset_sucess"
          component={PasswordResetSucess}
        />
        <Route
          exact
          path="/password_reset/:reset_id/:verification"
          component={PasswordResetChoose}
        />
        <Route exact path="/admin/signout" component={SignoutContainer} />
        <Route exact path="/admin/token_login" component={TokenLogin} />
        <Route path="/admin" component={Admin} />
        <Route path="/onboarding" component={Onboarding} />
        <Route path="/redirects" component={Redirects} />
      </Switch>
      <GeneralNotifications />
    </ThemeProvider>
  );
};
