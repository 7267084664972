import React from "react";
import { useGetFeatureAccessPermission } from "hooks/user";
import ResourceAlert, {
  AlertPopoverProps,
} from "@udok/lib/components/LimitedResourceAlert";
import {
  PlanFeaturesPermissions,
  ActiveSubscription,
} from "@udok/lib/api/models";
import SubscriptionDialog from "containers/Marketing/SubscriptionDialog";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";

const [rendererSubDialog, promiseSubDialog] = createModal(
  (props: {
    open: boolean;
    close: () => void;
    currentSubscription?: ActiveSubscription;
  }) => <SubscriptionDialog {...props} onSuccess={props.close} closeOnGoBack />
);

const ConnectedResourceAlertPopover = (
  props: AlertPopoverProps & {
    feature: PlanFeaturesPermissions | PlanFeaturesPermissions[];
    validateIfExceeded?: boolean;
  }
) => {
  const { feature = [], open, validateIfExceeded, close, ...others } = props;
  const [permissions, currentSub] = useGetFeatureAccessPermission(feature);
  const features = typeof feature === "string" ? [feature] : feature;

  const valid = React.useMemo(() => {
    return (
      features.filter((f) => {
        const { allowed, exceededLimit } = permissions[f];
        return validateIfExceeded ? allowed || !exceededLimit : allowed;
      }).length === features.length
    );
  }, [features, permissions, validateIfExceeded]);

  React.useEffect(() => {
    if (open && valid) {
      close(false);
    }
  }, [valid, open, close]);

  const handleClose = React.useCallback(
    (viewOffers: boolean) => {
      if (viewOffers) {
        promiseSubDialog?.({ currentSubscription: currentSub });
      }
      close(viewOffers);
    },
    [close, currentSub]
  );

  return (
    <>
      <ResourceAlert.Popover
        open={open && !valid}
        close={handleClose}
        {...others}
      />
      {rendererSubDialog}
    </>
  );
};

export default ConnectedResourceAlertPopover;
