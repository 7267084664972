import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

export type TextFieldTypeNumberProps = Omit<TextFieldProps, "onInput" | "type">;

const TextFieldTypeNumber = React.forwardRef(
  (props: TextFieldTypeNumberProps, ref: React.Ref<any>) => {
    const { variant, ...others } = props;
    const [val, setVal] = React.useState<string>();

    const handleInput = React.useCallback(
      (e: any) => {
        const data = e.nativeEvent?.data;
        if (!!data && data !== "." && data !== "," && !e?.target?.value) {
          e.target.value = val;
          return;
        }
        setVal(e.target.value);
      },
      [val]
    );

    return (
      <TextField
        ref={ref}
        type="number"
        onInput={handleInput}
        variant={variant as any}
        {...others}
      />
    );
  }
);

export default TextFieldTypeNumber;
