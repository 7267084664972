import React from "react";
import { GeoLocation } from "@udok/lib/api/models";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { searchLocationByCep } from "ducks/location";
import { TextFieldProps } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import AutocompleteCEP from "@udok/lib/components/Input/AutocompleteCEP";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSearch: {
      maxHeight: 30,
      marginLeft: theme.spacing(0.5),
    },
  })
);

export type SearchCepReturn = GeoLocation["address"] & {
  latitude: string;
  longitude: string;
};

export type SelectDoctorsProps = {
  onChange?: (value: Partial<SearchCepReturn>) => void;
  error: boolean;
  title?: React.ReactNode;
  placeholder?: string;
  value?: Partial<SearchCepReturn> | string;
  style?: React.CSSProperties;
  errorMessage?: string;
  automaticSearch?: boolean;
  addresRequired?: boolean;
  buttomLabel?: string;
} & Omit<
  TextFieldProps,
  "onInput" | "label" | "error" | "helperText" | "inputProps" | "onChange"
>;

function onFormatValue(value: Partial<SearchCepReturn> | string) {
  return typeof value !== "string"
    ? (value as Partial<SearchCepReturn>)?.cep ?? ""
    : value;
}

const SearchCep = React.forwardRef(
  (
    {
      onChange,
      title = "CEP",
      placeholder = "Informe o CEP",
      automaticSearch = true,
      error,
      errorMessage,
      value,
      style,
      addresRequired = true,
      buttomLabel = "Completar",
      ...others
    }: SelectDoctorsProps,
    ref: React.Ref<any>
  ) => {
    const [load, setLoad] = React.useState(false);
    const [err, setErr] = React.useState<string>();
    const [cep, setCep] = React.useState<string>();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { searchLocationByCep } = React.useMemo(
      () => mapDispatchToProps(dispatch),
      [dispatch]
    );

    React.useEffect(() => {
      if (!!value) {
        setCep(onFormatValue(value));
      }
    }, [value]);

    const shrinker = (v: any): object => {
      return (Boolean(v) && { shrink: Boolean(v) }) || {};
    };
    const onSearch = (cep?: string) => {
      if (cep) {
        setErr(undefined);
        setLoad(true);
        searchLocationByCep(cep)
          .then((r) => {
            if (r?.address?.cep) {
              let coord = r?.address?.coordinate ?? "";
              coord = coord.replace("(", "");
              coord = coord.replace(")", "");
              const coordinates = coord.split(",");
              onChange?.({
                ...r.address,
                latitude: coordinates[0],
                longitude: coordinates[1],
              });
            } else {
              if (!addresRequired) {
                onChange?.({ cep });
              }
              setErr("Cep não encontrado");
            }
          })
          .finally(() => setLoad(false));
      } else {
        setErr("Cep não encontrado");
      }
    };

    return (
      <AutocompleteCEP
        label={title}
        ref={ref}
        value={cep ?? ""}
        onChange={(val) => {
          setCep(val);
          if (automaticSearch && (val ?? "").length === 8) {
            onSearch(val);
          }
        }}
        onPress={onSearch}
        loading={load}
        buttomLabel={buttomLabel}
        buttonClass={classes.buttonSearch}
        error={(err && addresRequired) || error}
        fullWidth={false}
        InputLabelProps={{
          ...shrinker(value),
        }}
        {...(others as any)}
        helperText={err || (error ? errorMessage : "")}
      />
    );
  }
);

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  searchLocationByCep: (cep: string) => dispatch(searchLocationByCep(cep)),
});

export default SearchCep;
