import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { loginOTP, unauthorized } from "ducks/auth";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, Typography } from "@material-ui/core";
import SecureContainer from "containers/Auth/SecureContainer";

export type OTPContainerProps = {
  children: React.ReactNode;
  onLogin?: (r: any) => any;
};

const OTPContainer = (props: OTPContainerProps) => {
  const { children, onLogin } = props;
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { search, pathname } = useLocation();
  const [loading, setLoading] = React.useState(false);
  const query = new URLSearchParams(search);
  const otp = query.get("otp");

  React.useEffect(() => {
    if (!otp) {
      return;
    }
    setLoading(true);
    dispatch(unauthorized());
    dispatch(loginOTP(otp))
      .then(() => history.replace(pathname))
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, otp, history, pathname]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography style={{ padding: 15 }} variant="h3">
            Entrando no sistema, aguarde.
          </Typography>
          <CircularProgress />
        </div>
      ) : (
        <SecureContainer onLogin={onLogin}>{children}</SecureContainer>
      )}
    </div>
  );
};

export default OTPContainer;
