import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import StatusView from "@udok/lib/components/UserStatus/StatusView";
import { useGetClientsStatus } from "hooks/socket";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemUserStatus: {
      position: "absolute",
      bottom: "25%",
      left: theme.spacing(3),
    },
  })
);

export type ItemComponentProps = {
  userID: string;
  onClick?: (userID: string, e: React.MouseEvent<HTMLDivElement>) => void;
  handleClickLink: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  useLink?: boolean;
} & any;

const ItemComponent = (props: ItemComponentProps) => {
  const { userID, onClick, handleClickLink } = props;
  const [status] = useGetClientsStatus(userID);
  const classes = useStyles();

  const handlePress = async (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(userID, e);
  };

  const children = (
    <>
      {props.children}
      <StatusView
        size="small"
        classes={{
          root: classes.itemUserStatus,
        }}
        status={Boolean(status)}
      />
    </>
  );
  if (onClick) {
    return (
      <div {...props} style={{ paddingRight: 85 }} onClick={handlePress}>
        {children}
      </div>
    );
  }
  return (
    <Link
      {...props}
      style={{ paddingRight: 85 }}
      onClick={handleClickLink}
      onAuxClick={handleClickLink}
    >
      {children}
    </Link>
  );
};

export default ItemComponent;
