import React from "react";
import { useSelector } from "react-redux";
import { DialogContentText, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  SubscriptionOffer,
  CreditCard,
  CustomerAddress,
  BillingInformationForm,
  ActiveSubscription,
} from "@udok/lib/api/models";
import TabPanel from "@udok/lib/components/TabPanel";
import { getBillingInformation } from "ducks/user";
import SubscriptionOfferList from "containers/Marketing/SubscriptionOfferList";
import CreditCardSelector from "containers/Marketing/CreditCardSelector";
import EditSubscriptionAddress from "containers/Marketing/EditSubscriptionAddress";
import EditSubscriptionInfo from "containers/Marketing/EditSubscriptionInfo";
import ConfirmSubscription from "containers/Marketing/ConfirmSubscription";
import SuccessSubscription from "containers/Marketing/SuccessSubscription";
import SubscriptionSummary, {
  SubscriptionProperties,
} from "containers/Marketing/SubscriptionSummary";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "auto",
      display: "flex",
    },
    offerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      maxWidth: "100%",
    },
    offerTitle: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    successContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "100%",
      padding: theme.spacing(2),
    },
    gridContainer: {
      maxWidth: "100%",
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    ContainerPadding: {
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
    hidden: { display: "none" },
  })
);

type SubscriptionPropertiesTypes = {
  [SubscriptionProperties.offer]?: SubscriptionOffer;
  [SubscriptionProperties.creditCard]?: CreditCard;
  [SubscriptionProperties.address]?: CustomerAddress;
  [SubscriptionProperties.billingInfo]?: Omit<
    BillingInformationForm,
    "billingAddress"
  >;
};

export type CreateSubscriptionProps = {
  startOffer?: SubscriptionOffer;
  startScreen: SubscriptionProperties;
  offerViewSettings?: {
    title?: string;
    description?: string;
  };
  currentSubscription?: ActiveSubscription;
  onSuccess: () => void;
};

const CreateSubscription = (props: CreateSubscriptionProps) => {
  const {
    startOffer,
    startScreen,
    currentSubscription,
    offerViewSettings,
    onSuccess,
  } = props;
  const classes = useStyles();
  const { billingInformation } = useSelector(getBillingInformation);
  const [loading, setLoading] = React.useState(false);
  const [screen, setScreen] = React.useState<string>(startScreen);
  const [subsProps, setSubsProps] = React.useState<SubscriptionPropertiesTypes>(
    {
      [SubscriptionProperties.offer]: startOffer,
      [SubscriptionProperties.billingInfo]: billingInformation,
      [SubscriptionProperties.address]: billingInformation?.billingAddress,
    }
  );
  const grid2Ref = React.useRef<HTMLDivElement>(null);
  const selectedOffer = subsProps[SubscriptionProperties.offer];
  const selectedCard = subsProps[SubscriptionProperties.creditCard];
  const selectedAddress = subsProps[SubscriptionProperties.address];
  const selectedInfo = subsProps[SubscriptionProperties.billingInfo];

  const saveAndGoToNext = React.useCallback(
    (val: SubscriptionPropertiesTypes) => {
      setSubsProps((info) => {
        const newInfo = { ...info, ...val };
        let nextScreen =
          Object.keys(SubscriptionProperties).filter(
            (k) =>
              k !== SubscriptionProperties.success &&
              !newInfo[k as keyof typeof newInfo]
          )?.[0] ?? SubscriptionProperties.confirm;

        setScreen(nextScreen);
        return newInfo;
      });
    },
    []
  );

  const setOffer = React.useCallback(
    (offer: SubscriptionOffer) => {
      const val = { [SubscriptionProperties.offer]: offer };
      if (offer.price === 0 || !offer.billingEnabled) {
        setSubsProps((info) => ({ ...info, ...val }));
        setScreen(SubscriptionProperties.confirm);
        return;
      }
      saveAndGoToNext(val);
    },
    [saveAndGoToNext]
  );

  const setCard = React.useCallback(
    (card?: CreditCard) => {
      const cardAddress = card?.billingAddress;
      const val = {
        [SubscriptionProperties.creditCard]: card,
        ...(!billingInformation?.billingAddress && !!cardAddress
          ? {
              [SubscriptionProperties.address]: {
                name: cardAddress.name,
                zipcode: cardAddress.zipcode,
                neighborhood: cardAddress.neighborhood,
                street: cardAddress.street,
                street_number: cardAddress.streetNumber,
              },
            }
          : {}),
      };
      saveAndGoToNext(val);
    },
    [billingInformation, saveAndGoToNext]
  );

  const setAddress = React.useCallback(
    (address: CustomerAddress) => {
      saveAndGoToNext({ [SubscriptionProperties.address]: address });
    },
    [saveAndGoToNext]
  );

  const setBillingInformation = React.useCallback(
    (info: Omit<BillingInformationForm, "billingAddress">) => {
      saveAndGoToNext({ [SubscriptionProperties.billingInfo]: info });
    },
    [saveAndGoToNext]
  );

  React.useEffect(() => {
    const grid2 = grid2Ref?.current;
    if (screen !== SubscriptionProperties.offer && !!grid2) {
      setTimeout(() => {
        grid2?.scrollIntoView?.({
          behavior: "smooth",
          block: "start",
          inline: "center",
        });
      }, 10);
    }
  }, [grid2Ref, screen]);

  return (
    <div className={classes.root}>
      <TabPanel index={SubscriptionProperties.offer} value={screen}>
        <div className={classes.offerContainer}>
          <div className={classes.ContainerPadding}>
            <DialogContentText align="center">
              <Typography
                variant="h2"
                color="primary"
                className={classes.offerTitle}
              >
                {offerViewSettings?.title ?? "Escolha um de nossos planos"}
              </Typography>
              <Typography variant="h5" style={{ whiteSpace: "break-spaces" }}>
                {offerViewSettings?.description ??
                  "Para prosseguir selecione um dos planos abaixo."}
              </Typography>
            </DialogContentText>
          </div>
          <SubscriptionOfferList
            currentSubscription={currentSubscription}
            onSelect={setOffer}
          />
        </div>
      </TabPanel>
      <Grid
        container
        className={clsx(classes.gridContainer, {
          [classes.hidden]:
            (
              [
                SubscriptionProperties.offer,
                SubscriptionProperties.success,
              ] as string[]
            ).indexOf(screen) !== -1,
        })}
      >
        <Grid
          item
          md={6}
          style={{ maxWidth: 700 }}
          className={clsx(classes.gridItem, {
            [classes.hidden]: screen === SubscriptionProperties.success,
          })}
        >
          <DialogContentText>Dados da assinatura</DialogContentText>
          <SubscriptionSummary
            screen={screen as SubscriptionProperties}
            setScreen={setScreen}
            editable={!loading}
            selectedOffer={selectedOffer}
            selectedCard={selectedCard}
            selectedAddress={selectedAddress}
            selectedInfo={selectedInfo}
          />
        </Grid>
        <Grid ref={grid2Ref} item md={6} className={classes.gridItem}>
          <TabPanel index={SubscriptionProperties.creditCard} value={screen}>
            <div className={classes.ContainerPadding}>
              <DialogContentText>Selecione seu cartão.</DialogContentText>
              <CreditCardSelector
                selectedCard={selectedCard}
                onSelect={setCard}
              />
            </div>
          </TabPanel>
          <TabPanel index={SubscriptionProperties.address} value={screen}>
            <div className={classes.ContainerPadding}>
              <DialogContentText>
                Atualize seu endereço para cobranças.
              </DialogContentText>
              <EditSubscriptionAddress
                initialValues={selectedAddress}
                onSubmit={setAddress}
              />
            </div>
          </TabPanel>
          <TabPanel index={SubscriptionProperties.billingInfo} value={screen}>
            <div className={classes.ContainerPadding}>
              <DialogContentText>
                Atualize os seus dados para cobranças.
              </DialogContentText>
              <EditSubscriptionInfo
                initialValues={selectedInfo}
                onSubmit={setBillingInformation}
              />
            </div>
          </TabPanel>
          <TabPanel index={SubscriptionProperties.confirm} value={screen}>
            <div className={classes.ContainerPadding}>
              <ConfirmSubscription
                suofID={selectedOffer?.suofID ?? ""}
                crcaID={selectedCard?.crcaID}
                biinID={billingInformation?.biinID}
                free={
                  (selectedOffer?.price ?? 0) === 0 ||
                  !selectedOffer?.billingEnabled
                }
                selectedAddress={selectedAddress}
                selectedInfo={selectedInfo}
                loading={loading}
                setLoading={setLoading}
                onConfirm={() => setScreen(SubscriptionProperties.success)}
              />
            </div>
          </TabPanel>
        </Grid>
      </Grid>
      <TabPanel index={SubscriptionProperties.success} value={screen}>
        <div className={classes.successContainer}>
          <SuccessSubscription
            onStart={onSuccess}
            successMessage={selectedOffer?.displaySettings?.onSuccessMessage}
          />
        </div>
      </TabPanel>
    </div>
  );
};

export default CreateSubscription;
