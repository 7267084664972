import { RootState, AppThunk } from "ducks/state";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  PrescriptionLayoutForm,
  PrescriptionLayoutView,
  PrescriptionLayoutFilter,
  PrescriptionLayoutBindings,
  PrescriptionLayoutHistory,
} from "@udok/lib/api/models";
import {
  createPrescriptionLayout,
  updatePrescriptionLayout,
  fetchPrescriptionLayout,
  fetchPrescriptionLayouts,
  deletePrescriptionLayout,
  createPrescriptionLayoutBinding,
  fetchPrescriptionLayoutBinding,
  fetchPrescriptionLayoutHistory,
} from "@udok/lib/api/prescriptionLayout";
import { getToken, UNAUTHORIZED } from "./auth";
import { createSelector } from "reselect";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  prescriptionLayoutByID: {
    [plteID: string]: PrescriptionLayoutView | undefined;
  };
  layoutBindingByID: {
    [resourceID: string]: PrescriptionLayoutBindings | undefined;
  };
  layoutHistory: PrescriptionLayoutHistory[];
};

// Reducers
const initialState: InitialState = {
  prescriptionLayoutByID: {},
  layoutBindingByID: {},
  layoutHistory: [],
};

class PrescriptionLayoutTemplate extends Hen<InitialState> {
  layoutLoaded(data: PrescriptionLayoutView) {
    this.state.prescriptionLayoutByID[data.plteID] = data;
  }
  loadAllLayouts(data: PrescriptionLayoutView[]) {
    data.forEach((layout) => {
      this.state.prescriptionLayoutByID[layout.plteID] = layout;
    });
  }
  layoutRemoved(plteID: string) {
    delete this.state.prescriptionLayoutByID[plteID];
  }
  layoutBindingLoaded(data: PrescriptionLayoutBindings) {
    this.state.layoutBindingByID[data.resourceID] = data;
  }
  layoutHistoryLoaded(data: PrescriptionLayoutHistory[]) {
    this.state.layoutHistory = data;
  }
}

export const [Reducer, actions] = hen(
  new PrescriptionLayoutTemplate(initialState),
  {
    [UNAUTHORIZED]: () => initialState,
  }
);

// Selectors
export const prescriptionLayoutByID = (state: RootState) =>
  state.prescriptionLayout.prescriptionLayoutByID;
export const layoutBindingByID = (state: RootState) =>
  state.prescriptionLayout.layoutBindingByID;
export const layoutHistory = (state: RootState) =>
  state.prescriptionLayout.layoutHistory;

export const prescriptionLayoutList = createSelector(
  [prescriptionLayoutByID],
  (layoutByID) => {
    return {
      prescriptionLayouts: Object.keys(layoutByID)
        .map((plteID) => layoutByID[plteID])
        .filter((layout) => layout && !layout?.deletedAt)
        .sort((a, b) =>
          moment(b?.createdAt).diff(moment(a?.createdAt))
        ) as PrescriptionLayoutView[],
    };
  }
);

export const prescriptionLayoutListView = createSelector(
  [prescriptionLayoutByID],
  (layoutByID) => {
    return {
      list: Object.keys(layoutByID)
        .map((plteID) => layoutByID[plteID])
        .filter((layout) => layout && !layout?.deletedAt && !!layout?.doctID)
        .sort((a, b) =>
          moment(b?.createdAt).diff(moment(a?.createdAt))
        ) as PrescriptionLayoutView[],
    };
  }
);

export const getOneprescriptionLayout = (props: { plteID: string }) =>
  createSelector([prescriptionLayoutByID], (layoutByID) => {
    return {
      layout: layoutByID[props.plteID],
    };
  });

// Actions
export function createOnePrescriptionLayout(
  data: PrescriptionLayoutForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createPrescriptionLayout(apiToken, data)
      .then((r) => {
        dispatch(actions.layoutLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changePrescriptionLayout(
  plteID: string,
  data: PrescriptionLayoutForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updatePrescriptionLayout(apiToken, plteID, data)
      .then((r) => {
        dispatch(actions.layoutLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOnePrescriptionLayout(
  plteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchPrescriptionLayout(apiToken, plteID)
      .then((r) => {
        dispatch(actions.layoutLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPrescriptionLayouts(
  f?: PrescriptionLayoutFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchPrescriptionLayouts(apiToken, f)
      .then((r) => {
        dispatch(actions.loadAllLayouts(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removePrescriptionLayout(
  plteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deletePrescriptionLayout(apiToken, plteID)
      .then((r) => {
        dispatch(actions.layoutRemoved(r.plteID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOnePrescriptionLayoutBinding(
  data: PrescriptionLayoutBindings
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createPrescriptionLayoutBinding(apiToken, data)
      .then((r) => {
        dispatch(actions.layoutBindingLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOnePrescriptionLayoutBinding(
  resourceID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchPrescriptionLayoutBinding(apiToken, resourceID)
      .then((r) => {
        dispatch(actions.layoutBindingLoaded(r));
      })
      .catch((e) => {
        throw e;
      });
  };
}

export function fetchCachedPrescriptionLayout(
  plteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const layoutExist =
      !!state.prescriptionLayout.prescriptionLayoutByID[plteID];
    if (layoutExist) {
      return Promise.resolve();
    }
    return dispatch(loadOnePrescriptionLayout(plteID));
  };
}

export function fetchCachedPrescriptionLayoutBinding(
  resourceID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const layoutBindingExist =
      !!state.prescriptionLayout.layoutBindingByID[resourceID];
    if (layoutBindingExist) {
      return Promise.resolve();
    }
    return dispatch(getOnePrescriptionLayoutBinding(resourceID));
  };
}

export function loadPrescriptionLayoutHistory(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchPrescriptionLayoutHistory(apiToken)
      .then((r) => {
        dispatch(actions.layoutHistoryLoaded(r));
      })
      .catch((e) => {
        throw e;
      });
  };
}
