import * as React from "react";
import { connect } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  Location,
  AddressInfo,
  Preferences,
  DoctorPreference,
  PrescriptionLayoutBindType,
  PrescriptionLayoutBindings,
} from "@udok/lib/api/models";
import { createOneLocation, getListlocation } from "ducks/location";
import { createOneDoctorPreference } from "ducks/doctorPreferences";
import { createOnePrescriptionLayoutBinding } from "ducks/prescriptionLayout";
import { formatMoneyInFloat } from "@udok/lib/internal/util";
import Form, { initialValuesType } from "containers/Location/Form";

export type Props = {
  onSubmit?: (loc?: Location) => void;
  initialValues?: initialValuesType;
} & ReturnType<typeof getListlocation> &
  ReturnType<typeof mapDispatchToProps>;

const LocationCreate = (props: Props) => {
  const {
    onSubmit,
    createOneLocation,
    createOneDoctorPreference,
    createOnePrescriptionLayoutBinding,
    initialValues,
    list,
  } = props;
  const [loading, setLoading] = React.useState(false);

  const createPref = async (locaID: string, isDefault?: boolean) => {
    if (isDefault) {
      const pref = {
        prefID: Preferences.presDefaultLocation,
        options: {
          locaID: locaID,
        },
      } as DoctorPreference;
      await createOneDoctorPreference(pref);
    }
  };

  const createLayoutBinding = async (locaID: string, plteID?: string) => {
    if (!plteID) {
      return;
    }
    const data: PrescriptionLayoutBindings = {
      resourceID: locaID,
      type: PrescriptionLayoutBindType.location,
      attachResources: [plteID],
    };
    await createOnePrescriptionLayoutBinding(data);
  };

  const handleSubmit = (err: any, values: any, form: any) => {
    if (err) {
      return;
    }
    const locationParam = {
      address: `${values.street}, ${values.neighborhood}, ${values.regionSelected.cep} - ${values.uf}`,
      name: values.description,
      coord: `${values.regionSelected.latitude},${values.regionSelected.longitude}`,
      healthPlans:
        (values.healthPlans?.length || 0) > 0 ? values.healthPlans : ["1"],
      addressInfo: {
        locationName: values.description,
        locationPhones: values.locationPhones,
        cnes: values.cnes,
        cep: values.regionSelected.cep,
        uf: values.uf,
        street: values.street,
        neighborhood: values.neighborhood,
        city: values.city,
      } as AddressInfo,
      price: formatMoneyInFloat(values.price),
    } as Location;
    setLoading(true);
    createOneLocation(locationParam)
      .then(async (r) => {
        await Promise.all([
          createPref(r.locaID, values?.isDefault),
          createLayoutBinding(r.locaID, values?.plteID),
        ]).catch(console.warn);

        form.resetFields();
        onSubmit?.(r);
      })
      .catch(console.warn)
      .finally(() => setLoading(false));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      loading={loading}
      validateResource={list.length > 0}
    />
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createOneLocation: (l: Location) => dispatch(createOneLocation(l)),
  createOneDoctorPreference: (pref: DoctorPreference) =>
    dispatch(createOneDoctorPreference(pref)),
  createOnePrescriptionLayoutBinding: (data: PrescriptionLayoutBindings) =>
    dispatch(createOnePrescriptionLayoutBinding(data)),
});

export default connect(getListlocation, mapDispatchToProps)(LocationCreate);
