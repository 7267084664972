import MobileStepper from "@udok/lib/components/Stepper/MobileStepper";
import VerticalStepper from "@udok/lib/components/Stepper/VerticalSimpleStepper";
import Stepper from "@udok/lib/components/Stepper/CustomStepper";
import {
  StepStatus,
  Orientation,
  StepPalette,
} from "@udok/lib/components/Stepper/Types";
import * as Types from "@udok/lib/components/Stepper/Types";

export type StepItem = Types.StepItem;
export type PaletteProps = Types.PaletteProps;
export type StepperProps = Types.StepperProps;
export { StepStatus, Orientation, StepPalette, MobileStepper, VerticalStepper };
export default Stepper;
