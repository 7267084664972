import * as React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import DialogTitle from "@udok/lib/components/Dialog/Title";
import { LimitedResourceAlertTexts } from "@udok/lib/internal/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(1, 3, 3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 2, 2),
      },
    },
    dialogTitle: {
      alignItems: "center",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      "& > *:nth-child(n+2)": {
        width: "auto",
      },
    },
    descriptionContainer: {
      display: "flex",
      padding: theme.spacing(1, 0, 3),
      whiteSpace: "break-spaces",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export interface ResourceAlertDialogProps {
  open: boolean;
  close: (viewOffers: boolean) => void;
  title?: React.ReactNode;
  description?: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

const ResourceAlertDialog = ({
  open,
  close,
  maxWidth = "xs",
  title = LimitedResourceAlertTexts.title,
  description = LimitedResourceAlertTexts.description,
}: ResourceAlertDialogProps) => {
  const classes = useStyles();
  const handleClose = React.useCallback(() => {
    close(false);
  }, [close]);

  const handleViewOffers = React.useCallback(() => {
    close(true);
  }, [close]);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={maxWidth}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="resource-alert-dialog-title"
    >
      <DialogTitle
        id="resource-alert-dialog-title"
        onClose={handleClose}
        className={classes.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.descriptionContainer}>{description}</div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewOffers}
          >
            {LimitedResourceAlertTexts.buttonLabel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ResourceAlertDialog;
