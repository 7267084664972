import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { TextFieldProps } from "@material-ui/core";
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { PhonePrefixesByCountry } from "@udok/lib/internal/constants";
import { countryPhoneNormalizer, onlyNumbers } from "@udok/lib/internal/util";

type Option = {
  value: string;
  label: string;
};
export type AutoCompletePhoneProps = {
  onChange?: (value: string) => void;
  value?: string;
  filterPhones?: Option[];
  autocompleteStyle?: React.CSSProperties;
} & Omit<TextFieldProps, "onChange" | "value">;

const AutoCompletePhone = React.forwardRef(
  (props: AutoCompletePhoneProps, ref: React.Ref<any>) => {
    const {
      value = "",
      onChange,
      size,
      className,
      filterPhones,
      autocompleteStyle,
      ...others
    } = props;
    const filterOptions = React.useMemo(
      () => [...PhonePrefixesByCountry, ...(filterPhones ?? [])],
      [filterPhones]
    );

    const inputValue = React.useMemo(
      () => countryPhoneNormalizer(value, ""),
      [value]
    );
    const renderOption = React.useCallback((option: Option) => {
      return option.label;
    }, []);
    const handleChange = React.useCallback(
      (value: string) => {
        const v = countryPhoneNormalizer(value ?? "", "").replace(
          /[^\d+]+/g,
          ""
        );
        onChange?.(v);
      },
      [onChange]
    );
    const handleFilter = React.useCallback(
      (options: Option[], params: FilterOptionsState<Option>) => {
        const inputValue = params.inputValue;
        if (inputValue[0] === "+" && inputValue.length < 4) {
          return [...options]
            .slice(0, PhonePrefixesByCountry.length)
            .filter((opt) => opt.value.includes(inputValue));
        }
        if (options.length > PhonePrefixesByCountry.length) {
          return [...options]
            .slice(PhonePrefixesByCountry.length, options.length)
            .filter((opt) => opt.value.includes(onlyNumbers(inputValue)));
        }
        return [];
      },
      [filterPhones]
    );

    return (
      <Autocomplete<Option, false, false, true>
        ref={ref}
        freeSolo
        options={filterOptions}
        renderOption={renderOption}
        inputValue={inputValue}
        onChange={(e, val) => {
          e?.preventDefault?.();
          e?.stopPropagation?.();
          handleChange((val as Option)?.value ?? "");
        }}
        filterOptions={handleFilter}
        onInputChange={(e, value) => {
          if (!!e) {
            handleChange(value ?? "");
          }
        }}
        size={size}
        className={className}
        style={autocompleteStyle}
        fullWidth={others.fullWidth}
        renderInput={(params) => (
          <TextField variant={others.variant as any} {...params} {...others} />
        )}
      />
    );
  }
);

export default AutoCompletePhone;
