import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  UserProfile,
  ActiveSubscription,
  ActiveSubscriptionStatus,
  User,
  SEOListing,
  DoctorLicense,
  InvalidSubscription,
  BillingInformation,
  BillingInformationForm,
  SubscriptionOffer,
  FilterSubscriptionOffer,
  License,
} from "@udok/lib/api/models";
import {
  fetchUserMe,
  fetchCurrentSubscription,
  activateSubscription,
  cancelSubscription,
  getSubscriptionOffer,
  fetchSubscriptionOffers,
  updateUserProfile,
  oneTimeLogin,
  fetchUserByID,
  uploadPrivateFile,
  PrivateFileUploadedResponse,
  createVideoSession,
  fetchDoctorSEOListing,
  updateDoctorSEOListing,
  fetchBillingInformation,
  createBillingInformation,
  updateBillingInformation,
} from "@udok/lib/api/user";
import {
  fetchDoctorLicenses,
  createDoctorLicense,
  updateDoctorLicense,
  fetchDoctorLicense,
  deleteDoctorLicense,
} from "@udok/lib/api/doctor";
import { ErroDeadlineForChangeSubscription } from "@udok/lib/internal/constants";
import { getToken, getPayload, UNAUTHORIZED } from "./auth";
import { actions as localActions } from "./local";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  myProfile?: UserProfile;
  myActiveSubscription: ActiveSubscription | InvalidSubscription | null;
  userByID: { [k: string]: User | undefined };
  seoListing?: SEOListing;
  doctorLicenseByID: { [doliID: number]: DoctorLicense | undefined };
  filtredDoctorLicense: number[];
  billingInformation?: BillingInformation;
  offerByID: { [k: string]: SubscriptionOffer | undefined };
};

// Reducers
const initialState: InitialState = {
  myProfile: undefined,
  billingInformation: undefined,
  myActiveSubscription: null,
  userByID: {},
  doctorLicenseByID: {},
  filtredDoctorLicense: [],
  offerByID: {},
};

class UserSlice extends Hen<InitialState> {
  profileLoaded(v: UserProfile) {
    this.state.myProfile = v;
  }

  activeSubscriptionLoaded(s: ActiveSubscription | InvalidSubscription) {
    this.state.myActiveSubscription = s;
  }

  userLoaded(u: User) {
    this.state.userByID[u.userID] = u;
  }

  seoListingLoaded(l: SEOListing) {
    this.state.seoListing = l;
  }

  doctorLicensesLoaded(l: DoctorLicense[]) {
    this.state.filtredDoctorLicense = l.map((r) => {
      this.state.doctorLicenseByID[r.doliID] = r;
      return r.doliID;
    });

    const myProfile = this.state.myProfile;
    if (myProfile) {
      this.state.myProfile = {
        ...myProfile,
        doctor: {
          ...myProfile?.doctor,
          licenses: l,
        },
      } as UserProfile;
    }
  }

  doctorlicenseLoaded(l: DoctorLicense) {
    this.state.doctorLicenseByID[l.doliID] = l;
    const myProfile = this.state.myProfile;
    let licenses = myProfile?.doctor?.licenses ?? [];
    const index = licenses.findIndex((li) => li.doliID === l.doliID);
    if (index === -1) {
      licenses = [...licenses, l];
    } else {
      licenses[index] = l;
    }
    if (myProfile) {
      this.state.myProfile = {
        ...myProfile,
        doctor: {
          ...myProfile?.doctor,
          licenses,
        },
      } as UserProfile;
    }
  }

  doctorlicenseRemoved(l: DoctorLicense) {
    delete this.state.doctorLicenseByID[l.doliID];
    const myProfile = this.state.myProfile;
    let licenses = myProfile?.doctor?.licenses ?? [];
    licenses = licenses.filter((li) => li.doliID !== l.doliID);
    if (myProfile) {
      this.state.myProfile = {
        ...myProfile,
        doctor: {
          ...myProfile?.doctor,
          licenses,
        },
      } as UserProfile;
    }
  }

  billingInformationLoaded(bi: BillingInformation) {
    this.state.billingInformation = bi;
  }

  subscriptionOffersLoaded(offers: SubscriptionOffer[]) {
    offers.forEach((offer) => {
      this.state.offerByID[offer.suofID] = offer;
    });
  }

  subscriptionOfferLoaded(offer: SubscriptionOffer) {
    this.state.offerByID[offer.suofID] = offer;
  }
}

export const [Reducer, actions] = hen(new UserSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.user;
export const profileSelector = (state: RootState) => state.user.myProfile;
export const seoSelector = (state: RootState) => state.user.seoListing;
const userByIDSelector = (state: RootState) => state.user.userByID;
const doctorLicensesSelector = (state: RootState) =>
  state.user.doctorLicenseByID;
const billingInformationSelector = (state: RootState) =>
  state.user.billingInformation;
const subscriptionSelector = (state: RootState) =>
  state.user.myActiveSubscription;
const subscriptionOffersSelector = (state: RootState) => state.user.offerByID;
const FiltredDoctorLicenseSelector = (state: RootState) =>
  state.user.filtredDoctorLicense;

export const seoListingView = createSelector(seoSelector, (state) => {
  return {
    listing: state,
  };
});

export const getUserMe = createSelector(profileSelector, (state) => {
  return {
    currentUser: state,
  };
});

export const getBillingInformation = createSelector(
  billingInformationSelector,
  (billingInformation) => {
    return {
      billingInformation,
    };
  }
);

export const userByUserIDViewCreator = (props: { userID: string }) =>
  createSelector(mainSelector, (state) => {
    const usr = state.userByID[props.userID];
    return {
      user: usr,
    };
  });

export const avatarByUserIDViewCreator = (
  state: RootState,
  props: { userID: string }
) =>
  createSelector(userByIDSelector, (userByID) => {
    const usr = userByID[props.userID];
    return {
      src: usr?.avatar
        ? `${process.env.REACT_APP_BASE_PATH}/files/${usr?.avatar}`
        : "",
      name:
        usr?.patient?.name ??
        usr?.doctor?.name ??
        usr?.clinic?.name ??
        usr?.email ??
        "",
    };
  });

export const activeSubscriptionView = createSelector(
  [subscriptionSelector, getPayload],
  (myActiveSubscription, auth) => {
    return {
      subscription: myActiveSubscription,
      payload: auth.payload,
    };
  }
);

export const getValidSubscription = createSelector(
  [subscriptionSelector],
  (subscription) => {
    let sub: ActiveSubscription | undefined;
    if (!!subscription && !(subscription as any)?.error) {
      sub = subscription as ActiveSubscription;
    }
    return {
      subscription: sub,
    };
  }
);

export const userLoadedView = createSelector(
  [profileSelector, getPayload],
  (myProfile, auth) => {
    return {
      profile: myProfile,
      payload: auth.payload,
    };
  }
);

export const getMyLicenses = createSelector(
  [doctorLicensesSelector],
  (doctorLicenseByID) => {
    return {
      doctorLicenses: Object.keys(doctorLicenseByID)
        .map((doliID) => doctorLicenseByID[Number(doliID)])
        .filter((l) => !!l && !l.deletedAt)
        .sort((a, b) =>
          moment(b?.createdAt).diff(moment(a?.createdAt))
        ) as DoctorLicense[],
    };
  }
);

export const getFiltredLicenses = createSelector(
  [doctorLicensesSelector, FiltredDoctorLicenseSelector],
  (doctorLicenseByID, filtredList) => {
    return {
      filtredLicenses: filtredList
        .map((doliID) => doctorLicenseByID[Number(doliID)])
        .filter((l) => !!l && !l.deletedAt)
        .sort((a, b) =>
          moment(b?.createdAt).diff(moment(a?.createdAt))
        ) as DoctorLicense[],
    };
  }
);

export const getMyLicense = (props: { doliID: number }) =>
  createSelector([doctorLicensesSelector], (doctorLicenseByID) => {
    return {
      license: doctorLicenseByID[props.doliID],
    };
  });

export const getSubscriptionOffers = createSelector(
  [subscriptionOffersSelector],
  (subscriptionOffers) => {
    return {
      offers: Object.values(subscriptionOffers).filter((o) =>
        Boolean(o?.visible)
      ) as SubscriptionOffer[],
    };
  }
);

export const getOneSubscriptionOffers = (props: { suofID: string }) =>
  createSelector(subscriptionOffersSelector, (subscriptionOffers) => {
    return {
      offer: subscriptionOffers[props.suofID],
    };
  });

// Actions
export function loadUserMe(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchUserMe(apiToken)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function updateUser(u: UserProfile): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateUserProfile(apiToken, u)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Perfil atualizado",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function createOnetimeLogin(): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    let validUntil = new Date();
    validUntil.setHours(validUntil.getHours() + 1);
    return oneTimeLogin(validUntil, apiToken)
      .then((r) => {
        const otlogin = r;
        return otlogin.token;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Falha ao criar sessão de vídeo",
          }) as any
        );
        throw e;
      });
  };
}

export function loadActiveSubscription(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCurrentSubscription(apiToken)
      .then((r) => {
        // don't allow reducer errors to affect proper subscription identification
        try {
          dispatch(actions.activeSubscriptionLoaded(r));
        } catch (e) {
          dispatch(
            newNotification("general", {
              status: "error",
              message: (e as any).message,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          actions.activeSubscriptionLoaded({
            status: ActiveSubscriptionStatus.invalid,
            error: "Network Error",
          })
        );
      });
  };
}

export function activateOneSubscription(form: {
  suofID: string;
  crcaID?: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return activateSubscription(apiToken, form)
      .then((r) => {
        dispatch(actions.activeSubscriptionLoaded(r));
      })
      .catch((e) => {
        if (
          ((e as any)?.message ?? "").indexOf(
            ErroDeadlineForChangeSubscription
          ) > -1
        ) {
          throw e;
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e as any).message,
          })
        );
        throw e;
      });
  };
}

export function cancelOneSubscription(subsID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return cancelSubscription(apiToken, subsID)
      .then(() => {
        dispatch(loadActiveSubscription);
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e as any).message,
          })
        );
      });
  };
}

export function loadSubscriptionOffers(
  f?: FilterSubscriptionOffer
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchSubscriptionOffers(f)
      .then((r) => {
        dispatch(actions.subscriptionOffersLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e as any).message,
          })
        );
      });
  };
}

export function loadOneSubscriptionOffer(
  subsID: string
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return getSubscriptionOffer(subsID)
      .then((r) => {
        dispatch(actions.subscriptionOfferLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e as any).message,
          })
        );
      });
  };
}

export function fetchCachedActiveSubscription(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const sub = state.user.myActiveSubscription;
    const subscriptionExist = !!sub && !(sub as any)?.error;
    if (subscriptionExist) {
      return Promise.resolve();
    }
    return dispatch(loadActiveSubscription());
  };
}

export function fetchProfileForUserID(userID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchUserByID(apiToken, userID)
      .then((r) => {
        dispatch(actions.userLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          }) as any
        );
      });
  };
}

export function fetchCachedProfileForUserID(
  userID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const userExist = Boolean(state.user.userByID[userID]);
    if (userExist) {
      return Promise.resolve();
    }
    return dispatch(fetchProfileForUserID(userID));
  };
}

export function fetchCachedUserMe(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    if (!!state.user.myProfile) {
      return Promise.resolve();
    }
    return dispatch(loadUserMe());
  };
}

export function savePrivateFile(
  file: File,
  name: string,
  permittedUser: string
): AppThunk<Promise<PrivateFileUploadedResponse>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;

    return uploadPrivateFile(apiToken, {
      file,
      name,
      permittedUsers: [permittedUser],
    }).then((r) => {
      return r;
    });
  };
}

export function newVideoSession(usrs: string[]): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    const userIDTarget = usrs.find(
      (u) => u !== state.auth.token.payload.userID
    );

    return createVideoSession(apiToken, usrs).then((r) => {
      if (userIDTarget) {
        dispatch(
          localActions.updateViseIDCache({
            viseID: r.viseID,
            userID: userIDTarget,
            expiresAt: r.expiresAt,
          })
        );
      }
      return r.viseID;
    });
  };
}

export function loadSEOListing(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const doctID = t.token.payload.doctID;
    const apiToken = "Bearer " + t.token.raw;
    return fetchDoctorSEOListing(apiToken, doctID)
      .then((r) => {
        dispatch(actions.seoListingLoaded(r));
      })
      .catch((e) => {
        dispatch(actions.seoListingLoaded({ doctID, slug: "" }));
        // do nothing
      });
  };
}

export function updateSEOListing(l: SEOListing): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const doctID = t.token.payload.doctID;
    const apiToken = "Bearer " + t.token.raw;
    return updateDoctorSEOListing(apiToken, { ...l, doctID })
      .then((r) => {
        dispatch(actions.seoListingLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          }) as any
        );
      });
  };
}

export function loadMyLicenses(filter?: {
  prescriptionAllowedLicenses?: boolean;
}): AppThunk<Promise<DoctorLicense[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctorLicenses(apiToken, filter)
      .then((r) => {
        dispatch(actions.doctorLicensesLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneBillingInformation(
  form: BillingInformationForm
): AppThunk<Promise<BillingInformation | undefined>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createBillingInformation(apiToken, form)
      .then((r) => {
        dispatch(actions.billingInformationLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneBillingInformation(
  form: BillingInformationForm
): AppThunk<Promise<BillingInformation | undefined>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateBillingInformation(apiToken, form)
      .then((r) => {
        dispatch(actions.billingInformationLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadBillingInformation(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchBillingInformation(apiToken)
      .then((r) => {
        dispatch(actions.billingInformationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedBillingInformation(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const infoExist = Boolean(state.user.billingInformation);
    if (infoExist) {
      return Promise.resolve();
    }
    return dispatch(loadBillingInformation());
  };
}

export function createOneLicense(
  data: License
): AppThunk<Promise<DoctorLicense>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createDoctorLicense(apiToken, data)
      .then((r) => {
        dispatch(actions.doctorlicenseLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneLicense(
  doliID: number,
  data: License
): AppThunk<Promise<DoctorLicense>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return updateDoctorLicense(apiToken, doliID, data)
      .then((r) => {
        dispatch(actions.doctorlicenseLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOneLicense(doliID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctorLicense(apiToken, doliID)
      .then((r) => {
        dispatch(actions.doctorlicenseLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneLicense(doliID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteDoctorLicense(apiToken, doliID)
      .then((r) => {
        dispatch(actions.doctorlicenseRemoved(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedLicense(doliID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const licenseExist = Boolean(state.user.doctorLicenseByID[doliID]);
    if (licenseExist) {
      return Promise.resolve();
    }
    return dispatch(getOneLicense(doliID));
  };
}
