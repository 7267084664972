import React from "react";
import {
  InputAdornment,
  Tooltip,
  TextField,
  TextFieldProps,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Options } from "./Types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Icons from "@udok/lib/components/Icon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    x: {
      display: "flex",
      flexDirection: "column",
    },
    listItemIcon: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
      },
    },
    iconButton: {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5, 0),
        marginRight: theme.spacing(1),
      },
    },
    signatureItem: {
      padding: theme.spacing(0, 3, 0, 0),
    },
    clearSignature: {
      right: 0,
    },
  })
);

const SignatureOptions = ({
  options,
  onChange,
  width,
  height,
}: {
  options: Options;
  onChange: (o: Options) => void;
  width: number;
  height: number;
}) => {
  const bgIsCentered =
    options.background.shape.x === width / 2 &&
    options.background.shape.y === height / 2;
  const textLine = (options?.text?.value ?? "").split("\n");
  const classes = useStyles();

  return (
    <List style={{ maxWidth: width }}>
      <ListItem style={{ padding: 0 }}>
        <ListItemIcon className={classes.listItemIcon}>
          <ToggleButton
            title="Mudar a visibilidade do item"
            iconOn={<Icons.Visibility />}
            iconOff={<Icons.VisibilityOff />}
            className={classes.iconButton}
            onToggle={(b) =>
              onChange({
                ...options,
                text: {
                  ...options.text,
                  visible: b,
                },
              })
            }
            value={options.text.visible}
          />
          <ColorPickerButton
            title="Mudar a cor do texto"
            className={classes.iconButton}
            onChange={(b) =>
              onChange({
                ...options,
                text: {
                  ...options.text,
                  color: b,
                },
              })
            }
            value={options.text.color}
          />
        </ListItemIcon>
        <ListItemText
          primary="Informações do profisisonal"
          secondary={
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                style={{
                  flexShrink: 0,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "100%",
                  marginRight: 8,
                }}
              >
                <b>Linha 1:</b> <span>{textLine?.[0]}</span>; <b>Linha 2:</b>
              </div>
              <TextField
                value={textLine?.[1]}
                fullWidth
                margin="none"
                onChange={(e) => {
                  onChange({
                    ...options,
                    text: {
                      ...options.text,
                      value: `${textLine?.[0]}\n${e.target.value}`,
                    },
                  });
                }}
                inputProps={{ style: { padding: 0 }, maxlength: 70 }}
              />
            </div>
          }
        />
      </ListItem>
      <ListItem className={classes.signatureItem}>
        <ListItemIcon className={classes.listItemIcon}>
          <ToggleButton
            title="Mudar a visibilidade do item"
            iconOn={<Icons.Visibility />}
            iconOff={<Icons.VisibilityOff />}
            className={classes.iconButton}
            onToggle={(b) =>
              onChange({
                ...options,
                signature: {
                  ...options.signature,
                  visible: b,
                },
              })
            }
            value={options.signature.visible}
          />
          <ColorPickerButton
            title="Mudar a cor da caneta"
            className={classes.iconButton}
            onChange={(b) =>
              onChange({
                ...options,
                signature: {
                  ...options.signature,
                  color: b,
                },
              })
            }
            value={options.signature.color}
          />
        </ListItemIcon>
        <ListItemText
          primary="Digitalização da assinatura"
          secondary="Desenhe na tela de pintura"
        />
        <ListItemSecondaryAction className={classes.clearSignature}>
          <ToggleButton
            title="Mudar a visibilidade do item"
            iconOn={<Icons.Eraser />}
            iconOff={<Icons.Eraser />}
            className={classes.iconButton}
            onToggle={(b) =>
              onChange({
                ...options,
                signature: {
                  ...options.signature,
                  lines: [],
                },
              })
            }
            value={true}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem style={{ padding: 0 }}>
        <ListItemIcon className={classes.listItemIcon}>
          <ToggleButton
            title="Mudar a visibilidade do item"
            iconOn={<Icons.Visibility />}
            iconOff={<Icons.VisibilityOff />}
            className={classes.iconButton}
            onToggle={(b) =>
              onChange({
                ...options,
                lateral: {
                  ...options.lateral,
                  visible: b,
                },
              })
            }
            value={options.lateral.visible}
          />
          <ToggleButton
            title="Alinhamento do item"
            iconOn={<Icons.FormatAlignLeft />}
            iconOff={<Icons.FormatAlignRight />}
            className={classes.iconButton}
            onToggle={(b) => {
              onChange({
                ...options,
                lateral: {
                  ...options.lateral,
                  alignLeft: b,
                },
              });
            }}
            value={options.lateral.alignLeft}
          />
        </ListItemIcon>
        <ListItemText
          primary="Imagem da lateral"
          secondary={
            <FileAsDataURLInput
              onChange={(img) => {
                onChange({
                  ...options,
                  lateral: {
                    ...options.lateral,
                    image: img,
                  },
                });
              }}
            />
          }
        />
      </ListItem>
      <ListItem style={{ padding: 0 }}>
        <ListItemIcon className={classes.listItemIcon}>
          <ToggleButton
            title="Mudar a visibilidade do item"
            iconOn={<Icons.Visibility />}
            iconOff={<Icons.VisibilityOff />}
            className={classes.iconButton}
            onToggle={(b) =>
              onChange({
                ...options,
                background: {
                  ...options.background,
                  visible: b,
                },
              })
            }
            value={options.background.visible}
          />
          <ToggleButton
            title="Centralizar item"
            iconOn={<Icons.CenterFocusStrong />}
            iconOff={<Icons.CenterFocusWeak />}
            className={classes.iconButton}
            onToggle={(b) => {
              onChange({
                ...options,
                background: {
                  ...options.background,
                  shape: {
                    ...options.background.shape,
                    x: width / 2,
                    y: height / 2,
                  },
                },
              });
            }}
            value={bgIsCentered}
          />
        </ListItemIcon>
        <ListItemText
          primary="Imagem de fundo"
          secondary={
            <FileAsDataURLInput
              onChange={(img) => {
                onChange({
                  ...options,
                  background: {
                    ...options.background,
                    image: img,
                  },
                });
              }}
            />
          }
        />
      </ListItem>
    </List>
  );
};

export default SignatureOptions;

function ToggleButton(props: {
  title: string;
  iconOn: React.ReactElement;
  iconOff: React.ReactElement;
  onToggle: (b: boolean) => void;
  value: boolean;
  className?: string;
}) {
  const { title, iconOn, iconOff, onToggle, value, className } = props;
  return (
    <Tooltip title={title} aria-label={title}>
      <IconButton onClick={() => onToggle(!value)} className={className}>
        {value ? iconOn : iconOff}
      </IconButton>
    </Tooltip>
  );
}

function ColorPickerButton(props: {
  onChange: (b: string) => void;
  value: string;
  title: string;
  className?: string;
}) {
  const { onChange, value, title, className } = props;
  return (
    <Tooltip title={title} aria-label={title}>
      <IconButton component="label" htmlFor={title} className={className}>
        <Icons.ColorLens />
        <input
          style={{ display: "none" }}
          type="color"
          id={title}
          name={title}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </IconButton>
    </Tooltip>
  );
}

// Omit bugs out variant method typing
type FileAsDataURLInputProps = Omit<TextFieldProps, "onChange" | "variant"> & {
  onChange: (s: string | null) => void;
};

function FileAsDataURLInput({
  onChange,
  value,
  ...textFieldProps
}: FileAsDataURLInputProps) {
  const [loading, setL] = React.useState(false);
  const ref = React.useRef<any>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const file = e.target?.files?.[0];
    reader.addEventListener(
      "load",
      () => {
        setL(false);
        onChange(reader.result as string | null);
      },
      false
    );

    if (file) {
      if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
        alert("Formato de arquivo inválido");
        return;
      }
      setL(true);
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    if (ref.current) {
      ref.current.value = "";
      onChange(null);
    }
  };

  return (
    <TextField
      type="file"
      InputProps={{
        endAdornment: (
          <InputAdornment component="span" position="end">
            {!loading ? (
              <IconButton aria-label="clear input" onClick={handleClick}>
                <Icons.HighlightOff />
              </IconButton>
            ) : (
              <CircularProgress size={12} />
            )}
          </InputAdornment>
        ),
        inputProps: {
          accept: ".jpg,.jpeg,.png,.gif",
          ref: ref,
        },
      }}
      {...textFieldProps}
      onChange={handleChange}
      fullWidth
    />
  );
}
