import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  ButtonProps,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@udok/lib/components/Dialog/DrawerDialog";
import DialogTitle from "@udok/lib/components/Dialog/Title";
import clsx from "clsx";

enum Palette {
  primary = "primary",
  secondary = "secondary",
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      padding: theme.spacing(3),
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3, 0, 0),
        flexDirection: "column",
      },
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    actions: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      color: theme.palette.neutral.main,
    },
    stepperButton: {
      width: "100%",
      borderRadius: 25,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      padding: "0.4rem 4rem",
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.light,
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    buttonColorSecondary: {
      backgroundColor: theme.palette.secondary.light,
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
    buttonColorError: {
      backgroundColor: theme.palette.error.light,
      "&:hover": {
        background: theme.palette.error.main,
      },
    },
    buttonColorWarning: {
      backgroundColor: theme.palette.warning.light,
      "&:hover": {
        background: theme.palette.warning.main,
      },
    },
    buttonColorInfo: {
      backgroundColor: theme.palette.info.light,
      "&:hover": {
        background: theme.palette.info.main,
      },
    },
    buttonColorSuccess: {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        background: theme.palette.success.dark,
      },
    },
    illustration: {
      flex: 1,
      display: "flex",
      paddingRight: theme.spacing(2),
      maxHeight: "45vh",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        paddingRight: 0,
        paddingBottom: theme.spacing(2),
      },
    },
    dialogTitle: {
      margin: 0,
      alignItems: "center",
      flexWrap: "nowrap",
      backgroundColor: theme.palette.primary.lightest,
    },
    oneLineText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      height: "fit-content",
    },
    scrollContainer: {
      felx: 1,
      overflow: "auto",
      scrollbarWidth: "thin",
      scrollbarColor: "#dad7d7 #F4F4F4",
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F4",
      },
      "&::-webkit-scrollbar": {
        width: 6,
        background: "#F4F4F4",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#dad7d7",
      },
    },
  })
);

export type Action = {
  label: string;
  color?: keyof typeof Palette;
  onClick: () => void;
  buttonProps?: ButtonProps;
};

export type StepItemViewProps = {
  text: React.ReactNode;
  disabled?: boolean;
  actions?: Action[];
  illustration?: React.ReactNode;
  classes?: {
    root?: string;
    illustration?: string;
    actions?: string;
    container?: string;
    stepperButton?: string;
  };
};

const StepItemView = (props: StepItemViewProps) => {
  const {
    text,
    disabled,
    actions = [],
    illustration,
    classes: classesProps,
  } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classesProps?.root, classes.root)}>
      {illustration ? (
        <div className={clsx(classesProps?.illustration, classes.illustration)}>
          {illustration}
        </div>
      ) : null}
      <div className={clsx(classesProps?.container, classes.container)}>
        <Typography variant="body1">{text}</Typography>
        <div className={clsx(classesProps?.actions, classes.actions)}>
          {actions.map((action, i) => {
            const color = action?.color ?? Palette.primary;
            return (
              <Button
                key={i}
                size="small"
                color="primary"
                variant="contained"
                onClick={action.onClick}
                disabled={disabled}
                className={clsx(
                  classes.stepperButton,
                  classesProps?.stepperButton,
                  {
                    [classes.buttonColorPrimary]: color === Palette.primary,
                    [classes.buttonColorSecondary]: color === Palette.secondary,
                    [classes.buttonColorError]: color === Palette.error,
                    [classes.buttonColorWarning]: color === Palette.warning,
                    [classes.buttonColorInfo]: color === Palette.info,
                    [classes.buttonColorSuccess]: color === Palette.success,
                  }
                )}
                {...action?.buttonProps}
              >
                {action.label}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StepItemView;

export type StepItemDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
};

export const StepItemDialog = (props: StepItemDialogProps) => {
  const { open, onClose, title, children } = props;
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        id="form-dialog-title"
        onClose={onClose}
        className={classes.dialogTitle}
        titleTypographyProps={{ className: classes.oneLineText }}
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.scrollContainer}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
